import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/login.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import MyInput from "../../components/MyInput";
import { RESET_PASSWORD_ENDPOINT } from "../../apis";
import config from "../../config";
import FormException from "../../exceptions/FormException";
import { Spinner } from "reactstrap";

const ResetPass = () => {
    const [params] = useSearchParams();
    const [email, setEmail] = useState(params.get("email") || "");
    const [token] = useState(params.get("token") || "");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");

    const [errors, setErrors] = useState({});

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const submit = async () => {
        try {
            setLoading(true);
            const status = await config.postApi(
                RESET_PASSWORD_ENDPOINT,
                config.defaultHeaders,
                JSON.stringify({
                    email,
                    password,
                    password_confirmation: passwordConfirmation,
                    token
                })
            );
            setLoading(false);
            setErrors({});
            if (status) {
                navigate("/signin");
            }
        } catch (e) {
            setLoading(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="Reset Password" />
            {/* Inner Banner End Here */}

            {/* Login Sec Start Here */}
            <section className="login_sec">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        {/* <div className="col-lg-6">
                            <div className="img-box">
                                <figure><img src={AboutPic} alt="" className="img-fluid" /></figure>
                            </div>
                        </div> */}
                        <div className="col-lg-6">
                            <div className="form-box">
                                <form action="profile.php">
                                    <div className="heading-wrapper">
                                        <h4>Create new password</h4>
                                    </div>
                                    {/* <div className="form-group">
                                        <input
                                            type="email"
                                            placeholder="Email"
                                            className="form-control"
                                        />
                                    </div> */}
                                    <MyInput
                                        type="email"
                                        value={email}
                                        placeholder="Enter Email"
                                        label="Email"
                                        errors={errors.email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    {/* <div className="form-group">
                                        <input
                                            type="password"
                                            placeholder="New Password"
                                            className="form-control"
                                        />
                                    </div> */}
                                    <MyInput
                                        type="password"
                                        value={password}
                                        placeholder="Enter New Password"
                                        label="Password"
                                        errors={errors.password}
                                        onChange={e =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                    <MyInput
                                        type="password"
                                        value={passwordConfirmation}
                                        placeholder="Enter Confirm Password"
                                        label="Confirm Password"
                                        errors={errors.password_confirmation}
                                        onChange={e =>
                                            setPasswordConfirmation(
                                                e.target.value
                                            )
                                        }
                                    />
                                    {/* <div className="form-group">
                                        <input
                                            type="password"
                                            placeholder="Confirm Password"
                                            className="form-control"
                                        />
                                    </div> */}
                                    {/* <div className="form-group">
                                        <input type="password" placeholder="Password" className="form-control" />
                                    </div> */}
                                    {/* <div className="remeberdv">
                                        <div className="form-group">
                                            <input type="checkbox" id="Remeber_me" className="form-check-input" />
                                            <label for="Remeber_me">Remember Me</label>
                                        </div>
                                        <div className="link">
                                            <Link to="#">Forgets Password?</Link>
                                        </div>
                                    </div> */}
                                    <div className="button-group">
                                        <button
                                            className="btn"
                                            type="button"
                                            onClick={submit}
                                            disabled={loading}
                                        >
                                            {loading ? <Spinner /> : "Submit"}
                                        </button>
                                    </div>
                                    {/* <div className="bottom-bar">
                                        <p>Don't have account? <Link to="#">Sign Up</Link></p>
                                    </div> */}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* login Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default ResetPass;
