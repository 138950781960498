import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/timeline.css";
import { Link, useParams } from "react-router-dom";
import { CATEGORY_ENDPOINT } from "../../apis";
import { connect } from "react-redux";
import config from "../../config";

const UserTimeline = ({ authToken }) => {
    const { id } = useParams();

    const [timelineCategories, setTimelineCategories] = useState([]);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        const headers = config.bearerHeaders(authToken);
        const res = await config.getApi(
            CATEGORY_ENDPOINT + "?get_for_member=true&member_id=" + id,
            headers
        );
        if (res) {
            setTimelineCategories(res);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner PageName="user timeline" />
            {/* InnerBanner End Here */}

            {/* Timelines Sec Start Here */}
            <section className="usertimeline_sec">
                <div className="container">
                    <div className="row">
                        {timelineCategories.map(timelineCategory => (
                            <div
                                className="col-lg-4"
                                key={`timeline_category_${timelineCategory.id}`}
                            >
                                <div className="timeline-box">
                                    <Link
                                        to={`/member-timeline/${id}/${timelineCategory.id}`}
                                    >
                                        <h3>{timelineCategory.name}</h3>
                                        <span className="count">
                                            {timelineCategory.timeline_count}
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* Timelines Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(UserTimeline);
