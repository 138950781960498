import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { MEDICALS_ENDPOINT } from "../../apis";
import config from "../../config";
import { TagsInput } from "react-tag-input-component";
import MyInput from "../MyInput";

const AddMedicalModal = ({
    toggle,
    isOpen,
    getData,
    editData,
    authToken,
    user_id = null
}) => {
    const [allergicTo, setAllergicTo] = useState([]);
    const [pdfFile, setPdfFile] = useState({});

    const handleCreate = async event => {
        event.preventDefault();
        if (editData) {
            try {
                // setSpinner(true);
                const headers = config.bearerMultipartHeaders(authToken);
                const data = new FormData();
                allergicTo.map(allergic => {
                    data.append("allergic_to[]", allergic);
                });
                if (pdfFile) {
                    data.append("pdf_file", pdfFile);
                }
                if (user_id) {
                    data.append("user_id", user_id);
                }
                const res = await config.putApi(
                    `${MEDICALS_ENDPOINT}/${editData.id}`,
                    headers,
                    data
                );
                if (res) {
                    setAllergicTo([]);
                    setPdfFile({});
                    getData();
                    toggle();
                }

                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        } else {
            try {
                // setSpinner(true);
                const headers = config.bearerMultipartHeaders(authToken);
                const data = new FormData();
                allergicTo.map(allergic => {
                    data.append("allergic_to[]", allergic);
                });
                if (pdfFile) {
                    data.append("pdf_file", pdfFile);
                }
                if (user_id) {
                    data.append("user_id", user_id);
                }
                const res = await config.postApi(
                    MEDICALS_ENDPOINT,
                    headers,
                    data
                );
                if (res) {
                    setAllergicTo([]);
                    setPdfFile({});
                    getData();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (editData) {
            setAllergicTo(editData?.allergic_to);
            setPdfFile(editData?.pdf_file);
        }
    }, [editData]);

    return (
        <Modal isOpen={isOpen} toggle={toggle} className="medicalModal">
            <ModalHeader toggle={toggle} className="h2">
                {editData ? "Edit Medical" : "Add Medical"}
            </ModalHeader>
            <ModalBody>
                <p className="small mb-1">Medical</p>
                <TagsInput
                    value={allergicTo}
                    onChange={setAllergicTo}
                    placeHolder="Enter options"
                    separators={[","]}
                />
                {editData && pdfFile ? (
                    <>
                        <p>PDF FILE</p>
                        <a
                            target="_blank"
                            href={
                                process.env.REACT_APP_MEDICAL_PDFS_URI + pdfFile
                            }
                        >
                            Open
                        </a>
                    </>
                ) : null}
                <MyInput
                    type="file"
                    accept=".pdf"
                    onChange={e => setPdfFile(e.target.files[0])}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn-sm" onClick={toggle}>
                    Cancel
                </Button>
                <Button className="btn-success btn-sm" onClick={handleCreate}>
                    {editData ? "Update" : "Create"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(AddMedicalModal);
