import React from "react";
import { FormFeedback, FormGroup, FormText, Input } from "reactstrap";

const MySelect = ({
    value,
    onChange = null,
    placeholder = "",
    errors = [],
    label = "",
    showLabel = false,
    inputId = "",
    options,
    inputClassName = undefined,
    inputStyle = undefined,
    bottomPlaceholder = undefined
}) => {
    return (
        <FormGroup>
            {showLabel && <label htmlFor={inputId}>{label}</label>}
            <Input
                id={inputId}
                type={"select"}
                invalid={errors.length > 0}
                value={value}
                onChange={onChange}
                className={inputClassName}
                style={inputStyle}
            >
                {placeholder ? (
                    <option value={null}>{placeholder}</option>
                ) : null}
                {options.map(option => (
                    <option
                        key={`option_${inputId}_${option.value}`}
                        value={option.value}
                        disabled={option.disabled}
                    >
                        {option.label +
                            (option.disabled ? " ( Missing Email )" : "")}
                    </option>
                ))}
            </Input>
            {bottomPlaceholder && (
                <FormText color="muted">{bottomPlaceholder}</FormText>
            )}
            <FormFeedback>{errors.map(err => err)}</FormFeedback>
            {/* <div className="invalid-feedback">{errors.map(err => err)}</div> */}
        </FormGroup>
    );
};

export default MySelect;
