import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/login.css";
import { Link } from "react-router-dom";
import { RegisterAction } from "../../redux/actions/auth";
import { connect } from "react-redux";
import FormException from "../../exceptions/FormException";
import MyInput from "../../components/MyInput";
import { Spinner } from "reactstrap";
import MySelect from "../../components/MySelect";

const Signup = ({ RegisterAction }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState(null);

    const [spinner, setSpinner] = useState(false);

    const [errors, setErrors] = useState({});

    const submit = async () => {
        try {
            setSpinner(true);
            await RegisterAction(
                firstName,
                lastName,
                email,
                password,
                dob,
                gender
            );
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="Sign Up" />
            {/* Inner Banner End Here */}

            {/* Login Sec Start Here */}
            <section className="login_sec text-center">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        {/* <div className="col-lg-6">
                            <div className="img-box">
                                <figure><img src={AboutPic} alt="" className="img-fluid" /></figure>
                            </div>
                        </div> */}
                        <div className="col-lg-6">
                            <div className="form-box">
                                <form action="/profile">
                                    <div className="heading-wrapper">
                                        {/* <h2>Sign In</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                        </p> */}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <MyInput
                                                value={firstName}
                                                onChange={e =>
                                                    setFirstName(e.target.value)
                                                }
                                                inputId="first_name"
                                                placeholder="First Name"
                                                errors={errors.first_name}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                value={lastName}
                                                onChange={e =>
                                                    setLastName(e.target.value)
                                                }
                                                inputId="last_name"
                                                placeholder="Last Name"
                                                errors={errors.last_name}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                value={email}
                                                onChange={e =>
                                                    setEmail(e.target.value)
                                                }
                                                inputId="email"
                                                placeholder="Email"
                                                errors={errors.email}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                value={password}
                                                onChange={e =>
                                                    setPassword(e.target.value)
                                                }
                                                inputId="password"
                                                placeholder="Password"
                                                type="password"
                                                errors={errors.password}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                value={dob}
                                                onChange={e =>
                                                    setDob(e.target.value)
                                                }
                                                inputId="dob"
                                                placeholder="DOB"
                                                errors={errors.dob}
                                                type="date"
                                                placeholderBellowText="Date of Birth"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MySelect
                                                inputStyle={{ height: 55 }}
                                                value={gender}
                                                onChange={e =>
                                                    setGender(e.target.value)
                                                }
                                                inputId="gender"
                                                placeholder="Gender"
                                                errors={errors.gender}
                                                options={[
                                                    {
                                                        label: "Male",
                                                        value: "Male"
                                                    },
                                                    {
                                                        label: "Female",
                                                        value: "Female"
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="button-group">
                                        <button
                                            className="btn"
                                            type="button"
                                            onClick={submit}
                                            disabled={spinner}
                                        >
                                            {spinner ? <Spinner /> : "Signup"}
                                        </button>
                                    </div>
                                    <div className="bottom-bar">
                                        <p>
                                            Already have an account?{" "}
                                            <Link to="/signin">Sign In</Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* login Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    RegisterAction: async (firstName, lastName, email, password, dob, gender) =>
        dispatch(
            RegisterAction(firstName, lastName, email, password, dob, gender)
        )
});

export default connect(null, mapDispatchToProps)(Signup);
