import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/contact.css";
import { ContactImg } from "../../constant";
import FormException from "../../exceptions/FormException";
import config from "../../config";
import { CONTACT_US_ENDPOINT } from "../../apis";
import { Spinner } from "reactstrap";
import MyInput from "../../components/MyInput";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    const [errors, setErrors] = useState({});

    const [spinner, setSpinner] = useState(false);

    const submit = async () => {
        try {
            setSpinner(true);
            await config.postApi(
                CONTACT_US_ENDPOINT,
                config.defaultHeaders,
                JSON.stringify({
                    full_name: firstName + " " + lastName,
                    email,
                    phone,
                    subject,
                    message
                })
            );
            setSpinner(false);
            setErrors({});
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setSubject("");
            setMessage("");
        } catch (e) {
            setSpinner(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    return (
        <>
            {/* Header Sec Start Here */}
            <Header />
            {/* Header Sec Start Here */}

            {/* Inner Banner Sec Start Here */}
            <InnerBanner PageName="Contact" />
            {/* Inner Banner Sec End Here */}

            {/* Contact Sec Start Here */}
            <section className="contact_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact_img">
                                <figure>
                                    <img
                                        src={ContactImg}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </figure>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="heading">
                                <h3>Get In Touch</h3>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <MyInput
                                        label="First Name"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={e =>
                                            setFirstName(e.target.value)
                                        }
                                        errors={errors.full_name}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <MyInput
                                        label="Last Name"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={e =>
                                            setLastName(e.target.value)
                                        }
                                        errors={errors.full_name}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <MyInput
                                        label="Email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        errors={errors.email}
                                        type="email"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <MyInput
                                        label="Phone"
                                        placeholder="Phone"
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        errors={errors.phone}
                                        type="tel"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <MyInput
                                        label="Subject"
                                        placeholder="Subject"
                                        value={subject}
                                        onChange={e =>
                                            setSubject(e.target.value)
                                        }
                                        errors={errors.subject}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <MyInput
                                        label="Message"
                                        placeholder="Message"
                                        value={message}
                                        onChange={e =>
                                            setMessage(e.target.value)
                                        }
                                        errors={errors.message}
                                        textarea
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <div className="button-group">
                                        <button
                                            className="btn"
                                            type="button"
                                            onClick={submit}
                                            disabled={spinner}
                                        >
                                            {spinner ? <Spinner /> : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contact Sec End Here */}

            {/* Footer Sec Start Here */}
            <Footer />
            {/* Footer Sec End Here */}
        </>
    );
};

export default Contact;
