import moment from "moment";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { CATEGORY_ENDPOINT } from "../../apis";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import config from "../../config";
import { review1, review2, review3 } from "../../constant";

const TimelineDetail = () => {
    const authToken = useSelector(state => state.auth.token);
    const { id } = useParams();

    const [loading, setLoading] = useState(true);

    const [category, setCategory] = useState({});

    const [open, setOpen] = useState(false);

    const [image, setImage] = useState([])

    useEffect(() => {
        getData();
        window.scrollTo(0, 0);
    }, []);

    const getData = async () => {
        try {
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(
                `${CATEGORY_ENDPOINT}/${id}?include_timeline=1`,
                headers
            );
            if (res) {
                setCategory(res.category);
                setLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner PageName={category?.name} />
            {/* InnerBanner End Here */}

            {/* Timeline Detail Page Start Here */}
            <section className="timeline-detail">
                <div className="container">
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <Spinner style={{ color: config.primaryColor }} />
                        </div>
                    ) : (
                        <div className="row">
                            {category.family_timeline.map(singleTimeline => {
                                if (
                                    singleTimeline.media[0].media_type.split(
                                        "/"
                                    )[0] === "image"
                                ) {
                                    return (
                                        <>
                                            <div
                                                className="col-lg-4"
                                                key={`single_timeline_${singleTimeline.id}`}
                                            >
                                                <div className="timeline-box">
                                                    <div className="img-box" onClick={() => setOpen(true)}>
                                                        <figure>
                                                            <img
                                                                src={`${process.env.REACT_APP_TIMELINE_URI}${singleTimeline.media[0].media_file}`}
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        </figure>
                                                        <div className="date">
                                                            <h4>
                                                                {moment(
                                                                    singleTimeline.created_at
                                                                ).format(
                                                                    "DD/MMM/YYYY"
                                                                )}
                                                            </h4>
                                                            {/* <h4>58 Likes</h4> */}
                                                        </div>
                                                    </div>
                                                    <div className="details timeline-detail-content">
                                                        <div className="inner-detail">
                                                            <div className="name">
                                                                <h3>
                                                                    {
                                                                        singleTimeline.title
                                                                    }
                                                                </h3>
                                                            </div>
                                                            <ul className="tags">
                                                                {singleTimeline.tagged_members.map(
                                                                    member => (
                                                                        <li
                                                                            key={`member_${member.member.id}`}
                                                                        >
                                                                            {
                                                                                member
                                                                                    .member
                                                                                    .full_name
                                                                            }
                                                                        </li>
                                                                    )
                                                                )}
                                                            </ul>
                                                            <p className="description">
                                                                {
                                                                    singleTimeline.description
                                                                }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );

                                } else if (
                                    singleTimeline.media[0].media_type.split(
                                        "/"
                                    )[0] === "video"
                                ) {
                                    return (
                                        <div
                                            className="col-lg-4"
                                            key={`single_timeline_${singleTimeline.id}`}
                                        >
                                            <div className="timeline-box video-timeline-box">
                                                <div className="video-box img-box">
                                                    <figure>
                                                        <video
                                                            controls
                                                            style={{ height: 250, width: '100%', objectFit: 'cover', borderRadius: '10px 10px 0px 0px' }}
                                                            src={`${process.env.REACT_APP_TIMELINE_URI}${singleTimeline.media[0].media_file}`}
                                                        />
                                                    </figure>
                                                    <div className="date">
                                                        <h4>
                                                            {moment(
                                                                singleTimeline.created_at
                                                            ).format("DD/MMM/YYYY")}
                                                        </h4>
                                                        {/* <h4>58 Likes</h4> */}
                                                    </div>
                                                </div>
                                                <div className="details timeline-detail-content">
                                                    <div className="inner-detail">
                                                        <div className="name">
                                                            <h3>
                                                                {
                                                                    singleTimeline.title
                                                                }
                                                            </h3>
                                                        </div>
                                                        <ul className="tags">
                                                            {singleTimeline.tagged_members.map(
                                                                member => (
                                                                    <li
                                                                        key={`member_${member.member.id}`}
                                                                    >
                                                                        {
                                                                            member
                                                                                .member
                                                                                .full_name
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                        <p className="description">
                                                            {
                                                                singleTimeline.description
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                            {/* LightBox */}
                            <Lightbox
                                open={open}
                                close={() => setOpen(false)}
                                slides={[{ src: review1 }, { src: review2 }]}
                            />
                            {/* LightBox */}
                        </div>
                    )}
                </div>
            </section >
            {/* Timeline Detail Page End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default TimelineDetail;
