import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import { connect } from "react-redux";
import { USER_INFO } from "../../apis";

const Sidebar = ({ user, authToken, user_id = null }) => {
    useEffect(() => {
        if (user_id) {
            getOtherUser(user_id);
        }
    }, [user_id]);

    const [otherUser, setOtherUser] = useState({});

    const getOtherUser = async id => {
        const headers = config.bearerHeaders(authToken);
        const res = await config.getApi(USER_INFO(id), headers);
        if (res) {
            setOtherUser(res);
        }
    };

    return (
        <>
            <div className="profile_sidebar">
                <div className="profile-header">
                    <div className="profile-img">
                        <figure>
                            <img
                                src={config.returnAvatar(
                                    otherUser.id ? otherUser : user
                                )}
                                alt=""
                                className="img-fluid"
                            />
                        </figure>
                    </div>
                    <div className="name">
                        <h4>
                            {otherUser.full_name
                                ? otherUser.full_name
                                : user.full_name}
                        </h4>
                        <p>
                            {otherUser.profession
                                ? otherUser.profession
                                : user.profession}
                        </p>
                        {user_id ? null : (
                            <Link to="/edit-profile">Edit Profile</Link>
                        )}
                    </div>
                </div>
                <ul className="menus">
                    <li className="menu-items">
                        <Link
                            to={`/profile/${user_id || ""}`}
                            className="menu-links"
                        >
                            Profile
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/family-stream-contribution`}
                            className="menu-links"
                        >
                            Family stream contribution
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/favorite-quotes/${user_id || ""}`}
                            className="menu-links"
                        >
                            Favorite Quotes
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/hobbies/${user_id || ""}`}
                            className="menu-links"
                        >
                            Interests/hobbies
                        </Link>
                    </li>
                    {/* <li className="menu-items">
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <button
                                            className="btn btn-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            <h5>Pets</h5>
                                            <i
                                                className="fa fa-angle-down"
                                                aria-hidden="true"
                                            ></i>
                                        </button>
                                    </h5>
                                </div>
                                <div
                                    id="collapseTwo"
                                    className="collapse"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <div className="card-body">Dog</div>
                                </div>
                            </div>
                        </div>
                    </li> */}
                    <li className="menu-items">
                        <Link
                            to={`/pets/${user_id || ""}`}
                            className="menu-links"
                        >
                            Pets
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/sports/${user_id || ""}`}
                            className="menu-links"
                        >
                            Sports & Sports Team
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/work/${user_id || ""}`}
                            className="menu-links"
                        >
                            Work
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/place-lived/${user_id || ""}`}
                            className="menu-links"
                        >
                            Places Lived
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/medical/${user_id || ""}`}
                            className="menu-links"
                        >
                            Medical: Blood Type/ Allergy
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/secrets/${user_id || ""}`}
                            className="menu-links"
                        >
                            Secret to life
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link
                            to={`/cars/${user_id || ""}`}
                            className="menu-links"
                        >
                            Cars
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
    authToken: state.auth.token
});

export default connect(mapStateToProps)(Sidebar);
