import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { FamilyTree } from "../../family-tree/familytree";
import config from "../../config";
import { CONNECTIONS_ENDPOINT, RELATIONS_ENDPOINT } from "../../apis";
import { connect } from "react-redux";
import AddMemberModal from "../../components/FamilyMember/AddMemberModal";
import AddConnectionModal from "../../components/FamilyMember/AddConnectionModal";
import { Link, useNavigate } from "react-router-dom";
import ImageBrowserModal from "../../components/ImageBrowserModal";

const timlineIcon = `
<?xml version="1.0" encoding="iso-8859-1"?>
<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg fill="#fff" height="25px" width="25px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 512 512" xml:space="preserve">
<g>
	<g>
		<path d="M503.983,111.165H281.632l30.242-20.969c2.16-1.497,3.449-3.959,3.449-6.589v-67.04V8.017
			c0-4.427-3.588-8.017-8.017-8.017H200.418c-4.428,0-8.017,3.589-8.017,8.017v8.551v67.041c0,2.733,1.393,5.278,3.695,6.752
			l32.506,20.804H8.017c-4.428,0-8.017,3.589-8.017,8.017v384.802C0,508.411,3.588,512,8.017,512H256h247.983
			c4.428,0,8.017-3.589,8.017-8.017V119.182C512,114.754,508.412,111.165,503.983,111.165z M247.983,440.384H54.514v-48.1h193.47
			V440.384z M247.983,333.495H54.514v-48.1h193.47V333.495z M247.983,226.605H54.514v-48.1h193.47V226.605z M247.983,162.472H46.497
			c-4.428,0-8.017,3.589-8.017,8.017v64.134c0,4.427,3.588,8.017,8.017,8.017h201.486v26.722H46.497
			c-4.428,0-8.017,3.589-8.017,8.017v64.134c0,4.427,3.588,8.017,8.017,8.017h201.486v26.722H46.497
			c-4.428,0-8.017,3.589-8.017,8.017v64.134c0,4.427,3.588,8.017,8.017,8.017h201.486v39.549H16.033V127.198h231.95V162.472z
			 M255.822,109.55l-47.387-30.329V16.568v-0.534h90.856v0.534v62.844L255.822,109.55z M264.017,178.505h39.549v48.1h-39.549
			V178.505z M264.017,285.395h112.234v48.1H264.017V285.395z M264.017,392.284h176.367v48.1H264.017V392.284z M495.967,495.967
			h-231.95v-39.549h184.384c4.428,0,8.017-3.589,8.017-8.017v-64.134c0-4.427-3.588-8.017-8.017-8.017H264.017v-26.722h120.25
			c4.428,0,8.017-3.589,8.017-8.017v-64.134c0-4.427-3.588-8.017-8.017-8.017h-120.25v-26.722h47.566
			c4.428,0,8.017-3.589,8.017-8.017v-64.134c0-4.427-3.588-8.017-8.017-8.017h-47.566v-35.273h231.95V495.967z"/>
	</g>
</g>
</svg>`;

const eyeIcon = `<?xml version='1.0' encoding='utf-8'?>
<!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'>
<!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
<svg fill="#fff" height="25px" width="25px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 512 512">
  <g>
    <path d="m494.8,241.4l-50.6-49.4c-50.1-48.9-116.9-75.8-188.2-75.8s-138.1,26.9-188.2,75.8l-50.6,49.4c-11.3,12.3-4.3,25.4 0,29.2l50.6,49.4c50.1,48.9 116.9,75.8 188.2,75.8s138.1-26.9 188.2-75.8l50.6-49.4c4-3.8 11.7-16.4 0-29.2zm-238.8,84.4c-38.5,0-69.8-31.3-69.8-69.8 0-38.5 31.3-69.8 69.8-69.8 38.5,0 69.8,31.3 69.8,69.8 0,38.5-31.3,69.8-69.8,69.8zm-195.3-69.8l35.7-34.8c27-26.4 59.8-45.2 95.7-55.4-28.2,20.1-46.6,53-46.6,90.1 0,37.1 18.4,70.1 46.6,90.1-35.9-10.2-68.7-29-95.7-55.3l-35.7-34.7zm355,34.8c-27,26.3-59.8,45.1-95.7,55.3 28.2-20.1 46.6-53 46.6-90.1 0-37.2-18.4-70.1-46.6-90.1 35.9,10.2 68.7,29 95.7,55.4l35.6,34.8-35.6,34.7z"/>
  </g>
</svg>`;

// FamilyTree.templates.tommy.img_0 =
// '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" x="20" y="-15" width="80" height="80"></image>';
// FamilyTree.templates.tommy.img_1 =
// '<image preserveAspectRatio="xMidYMid slice" xlink:href="{val}" x="150" y="-15" width="80" height="80"></image>';

const FamilyContribution = ({ auth }) => {
    const [familyMembers, setFamilyMembers] = useState([]);
    const [memberModal, setMemberModal] = useState(false);
    const [addConnectionModal, setAddConnectionModal] = useState(false);

    const [modalSpinner, setModalSpinner] = useState(false);

    const [imageBrowserModal, setImageBrowserModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getRelations().then(() => {});
    }, []);

    const getRelations = async () => {
        try {
            const res = await config.getApi(
                RELATIONS_ENDPOINT,
                config.bearerHeaders(auth.token)
            );
            setFamilyMembers(res);
            initTree(res);
        } catch (e) {
            console.log(e);
        }
    };

    const initTree = async relations => {
        let nodes = relations.map(member => {
            let singleMember = {
                id: member.id,
                user_id: member.user_id,
                name: member.full_name,
                first_name: member.first_name,
                last_name: member.last_name,
                email: member.email,
                gender: member.gender ? member.gender.toLowerCase() : "",
                avatar: config.returnAvatar(member),
                dob: member.dob,
                dod: member.dod
            };
            if (member.father_id.length > 0) {
                singleMember["fid"] = member.father_id[0];
            }
            if (member.mother_id.length > 0) {
                singleMember["mid"] = member.mother_id[0];
            }
            if (member.partner_ids.length > 0) {
                singleMember["pids"] = member.partner_ids;
            }
            return singleMember;
        });
        FamilyTree.miniMap.draggable = false;
        console.log("Family Tree is Trial", FamilyTree.isTrial());
        console.log("Family Tree Version", FamilyTree.VERSION);
        let family = new FamilyTree(
            document.getElementById("familyTreeContainer"),
            {
                miniMap: true,
                nodeTreeMenu: true,
                // nodeMouseClick: FamilyTree.action.none,
                editForm: {
                    buttons: {
                        share: null,
                        pdf: null,
                        timeline: {
                            text: "Timeline",
                            icon: timlineIcon
                        },
                        profile: {
                            text: "Profile",
                            icon: eyeIcon
                        }
                    },
                    titleBinding: "name",
                    photoBinding: "avatar",
                    generateElementsFromFields: false,
                    elements: [
                        [
                            {
                                label: "First Name",
                                binding: "first_name",
                                type: "textbox"
                            },
                            {
                                label: "Last Name",
                                binding: "last_name",
                                type: "textbox"
                            }
                        ],
                        {
                            label: "Email (Optional)",
                            binding: "email",
                            type: "textbox"
                            // vlidators: { email: "Must be a valid Email!" }
                        },
                        {
                            label: "Gender",
                            type: "select",
                            options: [
                                { value: "male", text: "Male" },
                                { value: "female", text: "Female" }
                            ],
                            binding: "gender"
                        },
                        [
                            {
                                label: "Date of Birth",
                                type: "date",
                                binding: "dob"
                            },
                            {
                                label: "Date of Death",
                                type: "date",
                                binding: "dod"
                            }
                        ],
                        {
                            label: "Avatar",
                            type: "textbox",
                            binding: "avatar"
                        }
                    ],
                    addMore: false
                    // addMoreBtn: false
                },
                nodeBinding: {
                    field_0: "name",
                    field_1: "gender",
                    field_2: "dob",
                    field_3: "first_name",
                    field_4: "last_name",
                    field_5: "email",
                    field_6: "dod",
                    img_0: "avatar"
                },
                template: "hugo",
                mode: "dark",
                nodes
            }
        );
        // family.generateId = async () => {
        //     try {
        //         const headers = config.bearerHeaders(auth.token);
        //         const res = await config.postApi(
        //             RELATIONS_ENDPOINT + "/generate-id",
        //             headers,
        //             JSON.stringify({ gender: "male" }),
        //             false
        //         );
        //         if (res) {
        //             setNewNode(res.id);
        //             return res.id.toString();
        //         }
        //     } catch (e) {
        //         console.log(e);
        //     }
        // };
        // family.on("removed", (e, args) => {
        //     console.log("Removed called", args);
        // });
        family.onUpdateNode(
            ({ updateNodesData, removeNodeId, addNodesData }) => {
                console.log("on updateNode", removeNodeId);
                if (removeNodeId) {
                    console.log("removing node with id: ", removeNodeId);
                    removeMember(removeNodeId);
                }
            }
        );
        family.on("update", async (e, args) => {
            if (args.addNodesData.length > 0) {
                args.addNodesData.forEach(async singleNode => {
                    console.log(singleNode);
                    try {
                        const headers = config.bearerHeaders(auth.token);
                        const res = await config.postApi(
                            RELATIONS_ENDPOINT + "/generate-id",
                            headers,
                            JSON.stringify({
                                gender: singleNode.gender,
                                balkan_node_id: singleNode.id,
                                mid: singleNode.mid,
                                fid: singleNode.fid,
                                pids: singleNode.pids
                            }),
                            false
                        );
                        if (res) {
                            console.log(res);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                });
            }

            setTimeout(() => {
                if (args.updateNodesData.length > 0) {
                    args.updateNodesData.forEach(async singleNode => {
                        await updateMember(
                            singleNode.id,
                            singleNode.first_name,
                            singleNode.last_name,
                            singleNode.email,
                            singleNode.avatar,
                            singleNode.gender,
                            singleNode.dob,
                            singleNode.dod,
                            singleNode.pids,
                            singleNode.mid,
                            singleNode.fid
                        );
                    });
                }
            }, 500);
            setTimeout(async () => {
                await getRelations();
            }, 1500);
        });
        family.editUI.on("button-click", (sender, args) => {
            if (args.name === "timeline") {
                navigate(`/member-timeline/${args.nodeId}`);
            } else if (args.name === "profile") {
                let user_id = family.get(args.nodeId).user_id;
                navigate(`/profile/${user_id}`);
            }
        });
    };

    const updateMember = async (
        id,
        first_name = null,
        last_name = null,
        email = null,
        avatar = null,
        gender = null,
        dob = null,
        dod = null,
        pid = [],
        mid = null,
        fid = null
    ) => {
        console.log("updating member with id : " + id);
        try {
            const headers = config.bearerHeaders(auth.token);
            const data = JSON.stringify({
                first_name,
                last_name,
                email,
                avatar,
                gender,
                dob,
                dod,
                pids: pid,
                mid,
                fid
            });
            const res = await config.putApi(
                RELATIONS_ENDPOINT + "/" + id,
                headers,
                data,
                false
            );
            if (res) {
                console.log("updated!");
            }
        } catch (e) {
            console.log(e);
        }
    };

    const [newNode, setNewNode] = useState("");

    const addMember = async payload => {
        try {
            setModalSpinner(true);
            let data = new FormData();
            data.append("first_name", payload.firstName);
            data.append("last_name", payload.lastName);
            data.append("dob", payload.dob);
            data.append("gender", payload.gender);
            data.append("connection_name", payload.connectionName);
            data.append("relation_to_member_id", payload.relationToUserId);
            if (payload.dod) {
                data.append("dod", payload.dod);
            }
            if (payload.deceased === true) {
                data.append("deceased", 1);
            }
            if (payload.avatar && payload.avatar.name) {
                data.append("avatar", payload.avatar);
            }
            const headers = config.bearerMultipartHeaders(auth.token);
            const res = await config.postApi(RELATIONS_ENDPOINT, headers, data);
            if (res) {
                await getRelations();
                setMemberModal(false);
            }
            setModalSpinner(false);
        } catch (e) {
            setModalSpinner(false);
            console.log(e);
        }
    };

    const removeMember = async id => {
        try {
            const headers = config.bearerMultipartHeaders(auth.token);
            const res = await config.deleteApi(
                RELATIONS_ENDPOINT + "/" + id,
                headers,
                false
            );
            if (res) {
                await getRelations();
            }
        } catch (e) {
            console.log(e);
        }
    };

    const addConnection = async payload => {
        try {
            setModalSpinner(true);
            let data = JSON.stringify({
                connection_name: payload.connectionName,
                relation_of_id: payload.relationOfId,
                relation_to_id: payload.relationToId
            });
            const res = await config.postApi(
                CONNECTIONS_ENDPOINT,
                config.bearerHeaders(auth.token),
                data
            );

            if (res) {
                await getRelations();
                setAddConnectionModal(false);
            }
            setModalSpinner(false);
        } catch (e) {
            setModalSpinner(false);
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Sec Start Here */}
            <InnerBanner PageName="Family Stream" />
            {/* InnerBanner Sec End Here */}

            <section className="profile_sec">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-3"> */}
                        {/* Sidebar Start Here */}
                        {/* <Sidebar /> */}
                        {/* Sidebar End Here */}
                        {/* </div> */}
                        <div className="col-lg-12">
                            <div className="top-buttons">
                                <div className="left-buttons">
                                    <Link className="back-to-home" to="/">
                                        <i
                                            className="fa fa-angle-left"
                                            aria-hidden="true"
                                        ></i>
                                        Back to home
                                    </Link>
                                    {/* <button
                                        type="button"
                                        className="btn text-white"
                                        style={{
                                            backgroundColor: config.primaryColor
                                        }}
                                        onClick={() => setMemberModal(true)}
                                    >
                                        Add Member
                                    </button> */}
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn text-white ml-2"
                                        style={{
                                            backgroundColor: config.primaryColor
                                        }}
                                        onClick={() =>
                                            setImageBrowserModal(true)
                                        }
                                    >
                                        Open Image Browser
                                    </button>
                                    <button
                                        type="button"
                                        className="btn text-white ml-2"
                                        style={{
                                            backgroundColor: config.primaryColor
                                        }}
                                        onClick={getRelations}
                                    >
                                        Reload Tree
                                    </button>
                                </div>
                                {/* <button
                                    type="button"
                                    className="btn text-white ml-2"
                                    style={{
                                        backgroundColor: config.primaryColor
                                    }}
                                    onClick={() => setAddConnectionModal(true)}
                                >
                                    Add Connection
                                </button> */}
                            </div>
                            <div className="family_contribution_content">
                                <div
                                    id="familyTreeContainer"
                                    style={{ marginTop: 20 }}
                                ></div>
                            </div>
                            <AddMemberModal
                                isOpen={memberModal}
                                toggle={() => setMemberModal(!memberModal)}
                                onSubmit={addMember}
                                members={familyMembers}
                                spinner={modalSpinner}
                            />
                            <AddConnectionModal
                                isOpen={addConnectionModal}
                                toggle={() =>
                                    setAddConnectionModal(!addConnectionModal)
                                }
                                onSubmit={addConnection}
                                members={familyMembers}
                                spinner={modalSpinner}
                            />
                            <ImageBrowserModal
                                isOpen={imageBrowserModal}
                                toggle={() =>
                                    setImageBrowserModal(!imageBrowserModal)
                                }
                            />
                        </div>
                        {/* <div className="col-lg-3"> */}
                        {/* Right Sidebar Start Here */}
                        {/* <RecentBar /> */}
                        {/* Right Sidebar End Here */}
                        {/* </div> */}
                    </div>
                </div>
            </section>

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(FamilyContribution);
