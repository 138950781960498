import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { SECRETS_ENDPOINT } from "../../apis";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import AddSecretsModal from "../../components/Secrets/AddSecretsModal";
import Spinner from "../../components/Spinner";
import config from "../../config";
import RecentBar from "./RecentBar";
import Sidebar from "./Sidebar";
import { useParams } from "react-router-dom";

const Secrets = ({ authToken }) => {
    const [spinner, setSpinner] = useState(false);
    const [addSecrets, setAddSecrets] = useState(false);
    const [secretsData, setSecretsData] = useState([]);
    const [editSecrets, setEditSecrets] = useState();

    const { id } = useParams();

    const handleEdit = val => {
        setEditSecrets(val);
        setAddSecrets(true);
    };

    const handleDelete = async val => {
        try {
            // setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            console.log({ headers });
            // const data = JSON.stringify({ id: val?.id });
            const res = await config.deleteApi(
                `${SECRETS_ENDPOINT}/${val.id}`,
                headers
                // data
            );
            if (res) {
                console.log({ res });
                // getHobbies();
            }
            getSecrets();
            // setSpinner(false);
        } catch (e) {
            // setSpinner(false);
            console.log(e);
        }
    };

    const getSecrets = async () => {
        setSpinner(true);
        try {
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(
                `${SECRETS_ENDPOINT}?user_id=${id || ""}`,
                headers
            );
            if (res) {
                console.log({ res });
                setSecretsData(res);
            }
            setSpinner(false);
        } catch (e) {
            console.log(e);
            setSpinner(false);
        }
    };

    useEffect(() => {
        getSecrets();
    }, []);

    return (
        <>
            {/* Header Sec Start Here */}
            <Header />
            {/* Header Sec End Here */}

            {/* InnerBanner Sec Start Here */}
            <InnerBanner PageName="Profile" />
            {/* InnerBanner Sec End Here */}

            {/* Pets Sec Start Here */}
            <section className="profile_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            {/* Sidebar Start Here */}
                            <Sidebar user_id={id} />
                            {/* Sidebar End Here */}
                        </div>
                        <div className="col-lg-6">
                            <div className="hobbies_content">
                                <div className="title mb-4">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-lg">
                                            <h3 className="mb-0">
                                                Secrets of life
                                            </h3>
                                        </div>
                                        <div className="col-lg-auto">
                                            <button
                                                className="btn btn-sm btn-success br-8 shadow-sm"
                                                onClick={() => {
                                                    setEditSecrets();
                                                    setAddSecrets(true);
                                                }}
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {spinner ? (
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-auto">
                                            <Spinner />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="table-resposnive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="name">
                                                        Secrets
                                                    </th>
                                                    <th className="edit">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {secretsData?.map(val => (
                                                    <tr key={val?.id}>
                                                        <td className="name">
                                                            {val?.secret}
                                                        </td>
                                                        <td className="edit">
                                                            <button
                                                                className="editBtn"
                                                                onClick={() =>
                                                                    handleEdit(
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-pencil-square-o"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                            <button
                                                                className="deleteBtn"
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        val
                                                                    )
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-trash-o"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {/* Right Sidebar Start Here */}
                            <RecentBar />
                            {/* Right Sidebar End Here */}
                        </div>
                    </div>
                </div>
            </section>
            {/* Pets Sec End Here */}

            {addSecrets && (
                <AddSecretsModal
                    isOpen={addSecrets}
                    toggle={() => setAddSecrets(pre => !pre)}
                    getData={getSecrets}
                    editData={editSecrets}
                    user_id={id}
                />
            )}

            {/* Footer Sec Start Here */}
            <Footer />
            {/* Footer Sec End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
    // hobbies: state.hobbies
});

const mapDispatchToProps = dispatch => ({
    // GetHobbysAction: async () => dispatch(await GetHobbysAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Secrets);
