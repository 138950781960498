import React from "react";
import Joyride from "react-joyride";

const WebTour = () => {
    const step = [
        {
            target: "#FamilyCode",
            content: '"Add a Family Code"',
            disableBeacon: true,
            locale: {
                skip: "Skip",
                close: "Next"
            }
        },
        {
            target: "#StreamTour",
            content:
                '"You can create your family Tree, (We call it your Family Stream)"',
            disableBeacon: true,
            locale: {
                skip: "Skip",
                close: "Next",
                last: "next",
                back: ""
            }
        },
        {
            target: ".family_contribution_content",
            content:
                '"remember that scrolling may reduce or enlarge your stream dramatically. If you dont see yourself in the center, trying zooming in and out."',
            disableBeacon: true,
            locale: {
                skip: "Skip",
                close: "Next",
                last: "next",
                back: ""
            }
        },
        {
            target: "#ProfileBanner",
            content:
                '"Welcome to your personal profile page, from here you can do several really important things..."',
            disableBeacon: true,
            locale: {
                skip: "Skip",
                close: "Next",
                back: ""
            }
        },
        {
            target: "#AddTimelineTour",
            content:
                '"You can also add anything you like to your own Timeline. Just remember that you must first add members to the Stream before you can add to their timeline."',
            disableBeacon: true,
            locale: {
                skip: "Skip",
                close: "Next",
                back: ""
            }
        },
        {
            target: "#ProfileBanner",
            content: '"You can add info to your profile"',
            disableBeacon: true,
            locale: {
                skip: "Skip",
                close: "Next",
                back: ""
            }
        },
        {
            target: "#ProfileBanner",
            content:
                '"And post just as you would in any social media, only with the peace of mind knowing only members of your stream can see"',
            disableBeacon: true,
            locale: {
                skip: "Skip",
                close: "Next",
                back: ""
            }
        }
    ];
    return (
        <>
            <Joyride
                steps={step}
                run={true}
                showSkipButton={true}
                styles={{
                    options: {
                        primaryColor: "#17723e"
                    }
                }}
                callback={data => {
                    if (data.action === "skip") {
                        localStorage.setItem("joyride_shown", "true");
                    } else if (data.action === "next") {
                        localStorage.setItem("joyride_shown", "false");
                    }
                }}
            />
        </>
    );
};

export default WebTour;
