import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner,
    TabContent,
    TabPane
} from "reactstrap";
import MyInput from "./MyInput";
import config from "../config";
import { connect } from "react-redux";
import { IMAGE_BROWSER_ENDPOINT } from "../apis";

const ImageBrowserModal = ({ isOpen, toggle, authToken }) => {
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);

    const [image, setImage] = useState({});

    const [activeTab, setActiveTab] = useState("0");

    const [uploading, setUploading] = useState(false);

    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        getImages().then();
    }, []);

    const getImages = async () => {
        try {
            setLoading(true);
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(IMAGE_BROWSER_ENDPOINT, headers);
            if (res) {
                setImages(res);
            }
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const upload = async () => {
        setUploading(true);
        const data = new FormData();
        data.append("image", image);
        const headers = config.bearerMultipartHeaders(authToken);
        const res = await config.postApi(IMAGE_BROWSER_ENDPOINT, headers, data);
        setUploading(false);
        if (res) {
            setImages([...images, res]);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl">
            <ModalHeader>Image Browser</ModalHeader>
            <ModalBody>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === "0" ? "active" : ""}
                            onClick={() => setActiveTab("0")}
                        >
                            Media Library
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={() => setActiveTab("1")}
                        >
                            Upload
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="0">
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center p-4">
                                <Spinner
                                    style={{ color: config.primaryColor }}
                                />
                            </div>
                        ) : (
                            <div className="mt-3 p-4">
                                <MyInput
                                    value={
                                        selectedImage && selectedImage.path
                                            ? process.env
                                                  .REACT_APP_IMAGE_BROWSER_URI +
                                              selectedImage.path
                                            : ""
                                    }
                                    label="Image Link"
                                    showLabel
                                />

                                <Row>
                                    {images.map(image => (
                                        <Col md="3">
                                            <div
                                                onClick={() =>
                                                    setSelectedImage(image)
                                                }
                                                style={
                                                    selectedImage &&
                                                    selectedImage.id == image.id
                                                        ? {
                                                              backgroundColor:
                                                                  "#ccc",
                                                              width: "100%",
                                                              height: 150,
                                                              alignItems:
                                                                  "center",
                                                              display: "flex",
                                                              justifyContent:
                                                                  "center",

                                                              // selected
                                                              borderStyle:
                                                                  "solid",
                                                              borderWidth: 5,
                                                              borderColor:
                                                                  config.primaryColor
                                                          }
                                                        : {
                                                              backgroundColor:
                                                                  "#ccc",
                                                              width: "100%",
                                                              height: 150,
                                                              alignItems:
                                                                  "center",
                                                              display: "flex",
                                                              justifyContent:
                                                                  "center"
                                                          }
                                                }
                                            >
                                                <img
                                                    key={`image_${image.id}`}
                                                    src={
                                                        process.env
                                                            .REACT_APP_IMAGE_BROWSER_URI +
                                                        image.path
                                                    }
                                                    style={{
                                                        height: 100,
                                                        width: 200,
                                                        objectFit: "contain"
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        )}
                    </TabPane>
                    <TabPane tabId="1">
                        <div className="mt-3">
                            <MyInput
                                showLabel
                                placeholder="Select Image"
                                accept="image/*"
                                label="Image"
                                // value={image}
                                type="file"
                                onChange={e => setImage(e.target.files[0])}
                            />
                            <Button
                                type="button"
                                onClick={upload}
                                disabled={uploading}
                            >
                                {uploading ? (
                                    <>
                                        <Spinner size="sm" />
                                        {" Uploading"}
                                    </>
                                ) : (
                                    "Upload"
                                )}
                            </Button>
                        </div>
                    </TabPane>
                </TabContent>
            </ModalBody>
            <ModalFooter>
                <Button type="button" color="danger" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(ImageBrowserModal);
