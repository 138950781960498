import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="Privacy Policy" />
            {/* Inner Banner End Here */}

            {/* Policy Sec Start Here */}
            <section className="policy">
                <div className="container">
                    <div className="heading-wrapper">
                        <h3>Privacy Policy</h3>
                    </div>
                    <div className="content-wrapper">
                        <p>
                            <span>
                                <span>
                                    <span>**Ancestream Privacy Policy**</span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>Effective Date: August 1, 2023</span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        At Ancestream, we are committed to
                                        protecting your privacy and safeguarding
                                        your personal information. This Privacy
                                        Policy outlines how we collect, use,
                                        disclose, and protect your data when you
                                        access or use our Software as a Service
                                        (SAAS) platform and related services. By
                                        using Ancestream&#39;s services, you
                                        consent to the practices described in
                                        this Privacy Policy.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>**1. Information We Collect**</span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        1.1 Personal Information: When you
                                        register for an account or use our
                                        services, we may collect personal
                                        information such as your name, email
                                        address, contact details, and other
                                        information you provide voluntarily.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        1.2 Usage Data: We collect non-personal
                                        information related to your use of
                                        Ancestream, including but not limited to
                                        device information, browser type, IP
                                        address, usage patterns, and referring
                                        URLs.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        1.3 Cookies and Similar Technologies:
                                        Ancestream uses cookies and similar
                                        technologies to enhance your experience,
                                        track usage patterns, and improve our
                                        services. You can control cookie
                                        preferences through your browser
                                        settings.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        **2. How We Use Your Information**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        2.1 Service Delivery: We use your
                                        personal information to provide you with
                                        the requested services and to maintain
                                        and improve our platform.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        2.2 Communication: We may use your
                                        contact information to send you
                                        important updates, newsletters, and
                                        promotional offers. You can opt-out of
                                        marketing communications at any time.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        2.3 Analytics: We analyze usage data to
                                        better understand user behavior and
                                        preferences, allowing us to improve our
                                        services and user experience.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        2.4 Legal Compliance: We may use and
                                        disclose your information to comply with
                                        applicable laws, regulations, legal
                                        processes, or governmental requests.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        **3. Data Sharing and Disclosure**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        3.1 Third-Party Service Providers: We
                                        may share your personal information with
                                        third-party service providers who assist
                                        us in providing our services. These
                                        providers are obligated to protect your
                                        data and use it solely for the purposes
                                        specified by us.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        3.2 Business Transactions: In the event
                                        of a merger, acquisition, or sale of
                                        assets, your personal information may be
                                        transferred to the acquiring entity.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        3.3 Legal Obligations: We may disclose
                                        your personal information to comply with
                                        legal requirements, enforce our
                                        policies, protect our rights, or respond
                                        to legal requests.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>**4. Data Security**</span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        4.1 Security Measures: Ancestream
                                        employs industry-standard security
                                        measures to protect your data from
                                        unauthorized access, alteration, or
                                        disclosure.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        4.2 Data Retention: We retain your
                                        personal information for as long as
                                        necessary to fulfill the purposes
                                        outlined in this Privacy Policy or as
                                        required by law.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>**5. Your Rights and Choices**</span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        5.1 Access and Correction: You may
                                        access and update your personal
                                        information through your account
                                        settings or by contacting us directly.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        5.2 Opt-Out: You have the right to
                                        opt-out of receiving marketing
                                        communications from us. You can do this
                                        by following the instructions provided
                                        in the communication or by contacting
                                        us.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>**6. Children&#39;s Privacy**</span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        6.1 Ancestream&#39;s services are not
                                        intended for use by individuals under
                                        the age of 18. We do not knowingly
                                        collect personal information from
                                        minors. If you believe a minor has
                                        provided us with personal information,
                                        please contact us, and we will take
                                        appropriate steps to remove the
                                        information.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        **7. Changes to this Privacy Policy**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        7.1 Ancestream may update this Privacy
                                        Policy from time to time. We will notify
                                        you of any significant changes through
                                        our website or via email.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>**8. Contact Us**</span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        8.1 If you have any questions or
                                        concerns regarding this Privacy Policy
                                        or the use of your personal information,
                                        please contact us at
                                        info@ancestream.com.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span>
                                <span>
                                    <span>
                                        By using Ancestream&#39;s services, you
                                        acknowledge that you have read,
                                        understood, and agreed to be bound by
                                        this Privacy Policy.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>
                    </div>
                </div>
            </section>
            {/* Policy Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default PrivacyPolicy;
