import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { LiveIcon, PhotosIcon } from "../../constant";
import RecentBar from "./RecentBar";
import Sidebar from "./Sidebar";
import "../../assets/css/profile.css";
import { connect } from "react-redux";
import config from "../../config";
import { AddPostAction } from "../../redux/actions/posts";
import Post from "../../components/Post";
import Spinner from "../../components/Spinner";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import { GetRelationsAction } from "../../redux/actions/relations";
import { GetFamilyTimelineLogAction } from "../../redux/actions/familyTimelineLog";
import { useParams } from "react-router-dom";
import { USER_INFO } from "../../apis";
import {
    GetMoreNewsFeedAction,
    GetNewsFeedAction
} from "../../redux/actions/newsfeed";

const Profile = ({
    authToken,
    user,
    newsFeed,
    relations,
    AddPostAction,
    GetRelationsAction,
    GetNewsFeedAction,
    GetMoreNewsFeedAction
}) => {
    const { id } = useParams();

    const [video, selectVideo] = useState();
    const [videoUrl, setVideoUrl] = useState();
    const [photo, selectPhoto] = useState();
    const [caption, setCaption] = useState("");
    const [location, setLocation] = useState("");

    const [otherUser, setOtherUser] = useState({});

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id) {
            getOtherUser(id);
        }
        GetRelationsAction().then(() => {
            GetNewsFeedAction()
                .then(() => {
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                });
            // GetPostsAction(id)
            //     .then(() => {
            //         setLoading(false);
            //     })
            //     .catch(err => {
            //         console.log(err);
            //     });
        });
    }, []);

    const loadMore = async () => {
        try {
            await GetMoreNewsFeedAction();
        } catch (e) {
            console.log(e);
        }
    };

    const submitPost = async () => {
        try {
            let media = [];
            if (photo) {
                media.push(photo);
            }
            if (video) {
                media.push(video);
            }
            await AddPostAction(caption, location, media, id);
            setCaption("");
            setLocation("");
        } catch (err) {
            console.log(err);
        }
    };

    const getOtherUser = async id => {
        const headers = config.bearerHeaders(authToken);
        const res = await config.getApi(USER_INFO(id), headers);
        if (res) {
            setOtherUser(res);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Sec Start Here */}
            <div className="profile_banner" id="ProfileBanner">
                <InnerBanner PageName="Profile" />
            </div>
            {/* InnerBanner Sec End Here */}

            <section className="profile_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            {/* Sidebar Start Here */}
                            <Sidebar user_id={id} />
                            {/* Sidebar End Here */}
                        </div>
                        <div className="col-lg-6">
                            <div className="profile_content">
                                <div className="status-card-wrapper">
                                    <div className="status-field-and-profile-wrapper">
                                        <div className="status-profile-wrapper">
                                            {otherUser.first_name ? (
                                                <img
                                                    src={config.returnAvatar(
                                                        otherUser
                                                    )}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            ) : (
                                                <img
                                                    src={config.returnAvatar(
                                                        user
                                                    )}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                            )}
                                        </div>
                                        <div className="status-field-wrapper">
                                            <ReactTextareaAutocomplete
                                                placeholder={`What's on your mind, ${
                                                    otherUser.first_name
                                                        ? otherUser.first_name
                                                        : user.first_name
                                                }?`}
                                                style={{ resize: "none" }}
                                                className="form-control"
                                                value={caption}
                                                onChange={e =>
                                                    setCaption(e.target.value)
                                                }
                                                loadingComponent={Spinner}
                                                trigger={{
                                                    "@": {
                                                        dataProvider: token =>
                                                            relations.filter(
                                                                item =>
                                                                    item.username
                                                                        .toLowerCase()
                                                                        .includes(
                                                                            token.toLowerCase()
                                                                        )
                                                            ),
                                                        output: item =>
                                                            `@${item.username}`,
                                                        component: ({
                                                            entity
                                                        }) => (
                                                            <div>
                                                                @
                                                                {
                                                                    entity.username
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                }}
                                            />
                                            <button
                                                className="add-stuff"
                                                type="button"
                                                onClick={submitPost}
                                            >
                                                <i
                                                    className="fa fa-paper-plane-o"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="status-list-wrapper">
                                        <div className="live-btn-wrapper buttons">
                                            {video && videoUrl ? (
                                                <div className="uploaded-file  position-relative">
                                                    <i
                                                        onClick={() => {
                                                            selectVideo({});
                                                            setVideoUrl("");
                                                        }}
                                                        className={
                                                            "btn btn-sm btn-primary fa fa-close position-absolute right-0"
                                                        }
                                                    ></i>
                                                    <video
                                                        controls
                                                        style={{
                                                            width: "100%",
                                                            height: 200
                                                        }}
                                                        src={videoUrl}
                                                    ></video>
                                                </div>
                                            ) : null}
                                            {photo ? (
                                                <div className="uploaded-file position-relative">
                                                    <i
                                                        onClick={() =>
                                                            selectPhoto(null)
                                                        }
                                                        className={
                                                            "btn btn-sm btn-primary fa fa-close position-absolute right-0"
                                                        }
                                                    ></i>
                                                    <img
                                                        className="preview"
                                                        src={URL.createObjectURL(
                                                            photo
                                                        )}
                                                        alt="preview"
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="live-btn-wrapper buttons">
                                            <button
                                                onClick={() => {
                                                    config.pickFile(e => {
                                                        if (
                                                            e.target.files
                                                                .length > 0
                                                        ) {
                                                            setVideoUrl(
                                                                URL.createObjectURL(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            );
                                                            console.log(
                                                                URL.createObjectURL(
                                                                    e.target
                                                                        .files[0]
                                                                )
                                                            );
                                                            selectVideo(
                                                                e.target
                                                                    .files[0]
                                                            );
                                                        }
                                                    }, "video/*");
                                                }}
                                                className="btn"
                                            >
                                                <img
                                                    src={LiveIcon}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <span>video</span>
                                            </button>
                                            <label
                                                onClick={() => {
                                                    config.pickFile(e => {
                                                        console.log(
                                                            e.target.files
                                                        );
                                                        if (
                                                            e.target.files
                                                                .length > 0
                                                        ) {
                                                            selectPhoto(
                                                                e.target
                                                                    .files[0]
                                                            );
                                                        }
                                                    });
                                                }}
                                            >
                                                <img
                                                    src={PhotosIcon}
                                                    className="img-fluid"
                                                    alt=""
                                                />
                                                <span>Photos</span>
                                            </label>
                                        </div>
                                        {/* <div className="live-btn-wrapper">
										<button type="button">
											<img src={bxsmile} className="img-fluid" alt="" />
											Feeling
										</button>
									</div> */}
                                    </div>
                                </div>
                                {loading ? (
                                    <Spinner alignCenter />
                                ) : (
                                    <>
                                        <div className="all-posts">
                                            {/* <div className="filter">
                                                <div className="form-group">
                                                    <select className="form-control">
                                                        <option>
                                                            Posts per pages
                                                        </option>
                                                        <option>5</option>
                                                        <option>10</option>
                                                        <option>15</option>
                                                        <option>20</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="before-last-week-posts weeks">
                                                {/* <div className="title">
                                                    <h3>Post from last week</h3>
                                                </div> */}
                                                {newsFeed.data.map(post => (
                                                    <Post
                                                        {...post}
                                                        key={post.id.toString()}
                                                    />
                                                ))}
                                            </div>
                                            {/* <div className="last-week-posts weeks">
                                        <div className="title">
                                            <h3>A post before that</h3>
                                        </div>
                                        <Post />
                                    </div> */}
                                        </div>
                                        {newsFeed.next_page_url ? (
                                            <ul className="pagination">
                                                <li>
                                                    <button
                                                        className="arrow pagi"
                                                        onClick={loadMore}
                                                    >
                                                        Load More
                                                    </button>
                                                </li>
                                            </ul>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {/* Right Sidebar Start Here */}
                            <RecentBar />
                            {/* Right Sidebar End Here */}
                        </div>
                    </div>
                </div>
            </section>

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token,
    user: state.auth.user,
    newsFeed: state.newsFeed,
    relations: state.relations,
    familyTimelineLog: state.familyTimelineLog
});

const mapDispatchToProps = dispatch => ({
    GetNewsFeedAction: async () => dispatch(await GetNewsFeedAction()),
    GetMoreNewsFeedAction: async () => dispatch(await GetMoreNewsFeedAction()),
    AddPostAction: async (caption, location, media, user_id = null) =>
        dispatch(await AddPostAction(caption, location, media, user_id)),
    GetRelationsAction: async () => dispatch(await GetRelationsAction()),
    GetFamilyTimelineLog: async () =>
        dispatch(await GetFamilyTimelineLogAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
