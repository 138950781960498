import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/timeline.css";
import { Link, useParams } from "react-router-dom";
import config from "../../config";
import { CATEGORY_ENDPOINT } from "../../apis";
import { connect } from "react-redux";

const TimelineCategory = ({ authToken }) => {
    const { id, category } = useParams();

    const [subcategory, setSubcategory] = useState({});

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(
                `${CATEGORY_ENDPOINT}/${category}?get_for_member=true&member_id=${id}`,
                headers
            );
            if (res) {
                setSubcategory(res);
                // setLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner
                PageName={
                    subcategory && subcategory.category
                        ? subcategory.category.name
                        : "timeline Categories"
                }
            />
            {/* InnerBanner End Here */}

            {/* User Timeline category */}
            <section className="timeline-category">
                <div className="container">
                    <div className="row">
                        {subcategory?.pending_curation?.map(timeline => (
                            <div className="col-lg-4 col-md-6">
                                <div className="pending-curation-timeline">
                                    {timeline.media[0].media_type.split(
                                        "/"
                                    )[0] === "image" ? (
                                        <img
                                            src={`${process.env.REACT_APP_TIMELINE_URI}${timeline.media[0].media_file}`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    ) : (
                                        <figure>
                                            <video
                                                controls
                                                style={{ height: 250 }}
                                                src={`${process.env.REACT_APP_TIMELINE_URI}${timeline.media[0].media_file}`}
                                            />
                                        </figure>
                                    )}

                                    <div className="details timeline-detail-content">
                                        <div className="inner-detail">
                                            <div className="name">
                                                <h3>{timeline.title}</h3>
                                            </div>
                                            <ul className="tags">
                                                {timeline?.tagged_members.map(
                                                    member => (
                                                        <li
                                                            key={`member_${member.member.id}`}
                                                        >
                                                            {
                                                                member.member
                                                                    .full_name
                                                            }
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                            <p className="description">
                                                {timeline.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="all-years">
                        <ul class="years">
                            {subcategory?.category?.child_categories?.map(
                                childCategory => (
                                    <li class="d-flex justify-content-around">
                                        <Link
                                            class="d-inline-block"
                                            to={`/member-timeline/${id}/${category}/${childCategory.id}`}
                                        >
                                            <span class="d-inline-block">
                                                {childCategory.name}
                                            </span>
                                            <span class="d-inline badge badge-success">
                                                {childCategory.timeline_count}
                                            </span>
                                        </Link>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </section>
            {/* User Timeline category */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(TimelineCategory);
