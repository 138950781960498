import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../assets/css/timeline.css";
import { Link, useParams } from "react-router-dom";
import config from "../../config";
import { CATEGORY_ENDPOINT, TIMLINE_ENDPOINT } from "../../apis";
import OverlaySpinner from "../../components/OverlaySpinner";
import { connect } from "react-redux";
import Spinner from "../../components/Spinner";
import MyBtn from "../../components/MyBtn";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import MyInput from "../../components/MyInput";
import TimelineModal from "../../components/TimelineModal/TimelineModal";

const Timeline = ({ authToken }) => {
    const [showTimeline, setShowTimeline] = useState(false);
    const [loading, setLoading] = useState(true);
    const [spinner, setSpinner] = useState(false);

    const { id } = useParams();

    const [category, setCategory] = useState({});
    const [pendingCuration, setPendingCuration] = useState([]);

    const [description, setDescription] = useState("");

    const [newCategory, setNewCategory] = useState("");

    const [editCategoryModal, setEditCategoryModal] = useState(false);

    const [editCategory, setEditCategory] = useState(null);

    const [editCategoryText, setEditCategoryText] = useState("");

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        try {
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(
                `${CATEGORY_ENDPOINT}/${id}`,
                headers
            );
            if (res) {
                setCategory(res.category);
                setDescription(res.category.description || "");
                setPendingCuration(res.pending_curation);
            }
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const addNewCategory = async () => {
        try {
            setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            const data = JSON.stringify({
                name: newCategory,
                parent_id: id
            });
            const res = await config.postApi(CATEGORY_ENDPOINT, headers, data);
            if (res) {
                getCategories();
            }
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            console.log(e);
        }
    };

    const updateCategory = async () => {
        try {
            setEditCategoryModal(false);
            setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            const data = JSON.stringify({ name: editCategoryText });
            const res = await config.putApi(
                `${CATEGORY_ENDPOINT}/${editCategory.id}`,
                headers,
                data
            );
            if (res) {
                getCategories();
            }
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            console.log(e);
        }
    };

    const [dragging, setDragging] = useState(null);

    const onDragStart = (e, timelineId) => {
        setDragging(timelineId);
    };

    const onDrop = (e, subCategoryId) => {
        // console.log(subCategoryId);
        // console.log(dragging);
        updateTimeline(dragging, { category_id: subCategoryId })
            .then()
            .catch();
    };

    const updateTimeline = async (timelineId, timelineData) => {
        try {
            setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            const data = JSON.stringify(timelineData);
            const res = await config.putApi(
                `${TIMLINE_ENDPOINT}/${timelineId}`,
                headers,
                data
            );
            if (res) {
                getCategories();
            }
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            console.log(e);
        }
    };

    const saveDescription = async () => {
        try {
            setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            const data = JSON.stringify({ description });
            const res = await config.putApi(
                `${CATEGORY_ENDPOINT}/${id}`,
                headers,
                data
            );
            if (res) {
                getCategories();
            }
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {loading ? (
                <section className="timeline_detail">
                    <div className="container d-flex align-items-center justify-content-center">
                        <Spinner />
                    </div>
                </section>
            ) : (
                <section className="timeline_detail">
                    <div className="container">
                        <div className="heading_wrapper">
                            <h4>Year</h4>
                            <h3 className="year">{category.name}</h3>
                        </div>
                        <div className="row">
                            {category.pinned.map(pinnedTimeline => (
                                <div
                                    className="col-md-6"
                                    key={`pinned_timeline_${pinnedTimeline.id}`}
                                >
                                    <div className="attachment_one">
                                        {pinnedTimeline.media.length > 0 &&
                                            pinnedTimeline.media[0].media_type.split(
                                                "/"
                                            )[0] === "video" ? (
                                            <div className="img-box">
                                                <figure>
                                                    <video
                                                        src={`${process.env.REACT_APP_TIMELINE_URI}${pinnedTimeline.media[0].media_file}`}
                                                        className="img-fluid"
                                                        controls
                                                    />
                                                </figure>
                                            </div>
                                        ) : (
                                            <div className="img-box">
                                                <figure>
                                                    <img
                                                        src={`${process.env.REACT_APP_TIMELINE_URI}${pinnedTimeline.media[0].media_file}`}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </figure>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            <div className="col-md-12">
                                <div className="intro">
                                    <div className="title">
                                        <h4>Written Intro</h4>
                                    </div>
                                    <textarea
                                        className="form-control"
                                        value={description}
                                        onChange={e =>
                                            setDescription(e.target.value)
                                        }
                                    />
                                </div>
                                <MyBtn
                                    title={"Save"}
                                    onClick={saveDescription}
                                />
                            </div>
                            {pendingCuration.length > 0 && (
                                <div className="col-md-12 mt-4">
                                    <div className="pending">
                                        <div className="title">
                                            <h3>Pending Curation</h3>
                                        </div>
                                        <ul className="pending-files">
                                            {pendingCuration.map(timeline => (
                                                <li
                                                    draggable
                                                    onDragStart={e =>
                                                        onDragStart(
                                                            e,
                                                            timeline.id
                                                        )
                                                    }
                                                    // onDragEnd={e =>
                                                    //     onDragEnd(e, timeline.id)
                                                    // }
                                                    key={`pending_curation_${timeline.id}`}
                                                >
                                                    {/* <span className="delete">
                                                        x
                                                    </span> */}
                                                    <button
                                                    // onClick={() => {
                                                    //     setShowTimeline(
                                                    //         true
                                                    //     );
                                                    // }}
                                                    >
                                                        {timeline.media.length >
                                                            0 &&
                                                            timeline.media[0].media_type.split(
                                                                "/"
                                                            )[0] === "video" ? (
                                                            <video
                                                                controls
                                                                src={`${process.env.REACT_APP_TIMELINE_URI}${timeline.media[0].media_file}`}
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        ) : (
                                                            <img
                                                                src={`${process.env.REACT_APP_TIMELINE_URI}${timeline.media[0].media_file}`}
                                                                alt=""
                                                                className="img-fluid"
                                                            />
                                                        )}

                                                        <div className="details timeline-detail-content">
                                                            <div className="inner-detail">
                                                                <div className="name">
                                                                    <h3>
                                                                        {
                                                                            timeline.title
                                                                        }
                                                                    </h3>
                                                                </div>
                                                                <ul className="tags">
                                                                    {timeline.tagged_members.map(
                                                                        member => (
                                                                            <li
                                                                                key={`tagged_member_${member.member.id}`}
                                                                            >
                                                                                {
                                                                                    member
                                                                                        .member
                                                                                        .full_name
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                                <p className="description">
                                                                    {
                                                                        timeline.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            )}
                            <div className="col-md-12">
                                <div className="all-years">
                                    <ul className="years">
                                        {category.child_categories?.map(
                                            (data, index) => (
                                                <li
                                                    key={`child_category_${data.id}`}
                                                    onDrop={e =>
                                                        onDrop(e, data.id)
                                                    }
                                                    onDragOver={e =>
                                                        e.preventDefault()
                                                    }
                                                    className="d-flex justify-content-around"
                                                >
                                                    <Link
                                                        className="d-inline-block"
                                                        to={`/timeline-detail/${data.id}`}
                                                    >
                                                        <span className="d-inline-block">
                                                            {data.name}
                                                        </span>{" "}
                                                        <span className="d-inline badge badge-success">
                                                            {
                                                                data.timeline_count
                                                            }
                                                        </span>{" "}
                                                    </Link>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setEditCategory(
                                                                data
                                                            );
                                                            setEditCategoryText(
                                                                data.name
                                                            );
                                                            setEditCategoryModal(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        Edit
                                                    </button>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="box">
                                    <h4>Birthday</h4>
                                    <button>Edit</button>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box">
                                    <h4>Life Event 1</h4>
                                    <button>Edit</button>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box">
                                    <h4>Life Event 2</h4>
                                    <button>Edit</button>
                                </div>
                            </div> */}
                            <div className="col-md-12">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="form-group d-flex">
                                        <input
                                            type="text"
                                            placeholder="Add a new category"
                                            className="form-control"
                                            value={newCategory}
                                            onChange={e =>
                                                setNewCategory(e.target.value)
                                            }
                                        />
                                        <MyBtn
                                            title={"Add"}
                                            onClick={addNewCategory}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <Modal isOpen={editCategoryModal}>
                <ModalHeader
                    toggle={() => setEditCategoryModal(!editCategoryModal)}
                >
                    Edit Category
                </ModalHeader>
                <ModalBody>
                    <MyInput
                        placeholder="Name"
                        inputId="edit_category_name"
                        label="Name"
                        value={editCategoryText}
                        onChange={e => setEditCategoryText(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="success"
                        onClick={updateCategory}
                    >
                        Save
                    </Button>
                    <Button
                        type="button"
                        color="danger"
                        onClick={() => setEditCategoryModal(false)}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>

            <TimelineModal
                isOpen={showTimeline}
                toggle={() => setShowTimeline(!showTimeline)}
                getData={pendingCuration}
            />

            {spinner && <OverlaySpinner />}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(Timeline);
