import { GET_NEWS_FEED, GET_MORE_NEWS_FEED } from "./type";
import config from "../../config";
import { NEWS_FEED_ENDPOINT } from "../../apis";

export const GetNewsFeedAction = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = config.bearerHeaders(token);
    const res = await config.getApi(`${NEWS_FEED_ENDPOINT}`, headers);
    if (res) {
        dispatch({ type: GET_NEWS_FEED, payload: res });
    }
};

export const GetMoreNewsFeedAction = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    const headers = config.bearerHeaders(token);
    const url = getState().newsFeed.next_page_url;
    const res = await config.getApi(url, headers);
    if (res) {
        dispatch({ type: GET_MORE_NEWS_FEED, payload: res });
    }
};
