import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Logo, NewLogo, footerLogo } from "../../constant";
import { gsap } from "gsap";
import { Timeline } from "gsap/gsap-core";
import { connect } from "react-redux";
import { LogoutAction } from "../../redux/actions/auth";
import WebTour from "../WebTour";

const Header = ({ auth, logout }) => {
    const animated = !!sessionStorage.getItem("animated");

    if (animated === false) {
        setTimeout(() => {
            sessionStorage.setItem("animated", true);
        }, 4000);
    }

    window.onunload = function () {
        sessionStorage.removeItem("animated");
    };

    const Logoutfn = () => {
        logout().then();
    };

    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    // const location = window.location.pathname;

    let joyrideShown = localStorage.getItem("joyride_shown");

    useEffect(() => {
        var tl = new Timeline();
        tl = gsap.timeline({
            defaults: {
                duration: 2,
                ease: "expo.inOut"
            }
        });

        tl.to(".slide-1", {
            width: 0
        }).to("#introduction", {
            height: 0
        });
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 60);
        });
    }, []);
    const navigate = useNavigate();
    return (

        <>
            {joyrideShown === "true" ? null : <WebTour />}
            {!animated ? (
                <div id="introduction">
                    <div className="slide-1 slide"></div>
                    <div className="slide-2 slide"></div>
                    <div className="image-container">
                        <figure>
                            <img src={Logo} alt="" className="img-fluid" />
                        </figure>
                    </div>
                </div>
            ) : null}
            {/* Header Start Here */}
            <header
                className={scroll ? "headerTop active" : "headerTop"}
                id={location.pathname === "/" ? "normalHeader" : "stickyHeader"}
            >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-2 col-md-5 col-5">
                            <div className="logoDv">
                                <Link to="/">
                                    <figure>
                                        <img
                                            src={location.pathname === "/" ? NewLogo : footerLogo}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </figure>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-1 col-1">
                            <ul className="menus">
                                {auth.token ? (
                                    <>
                                        <li className="menu-items">
                                            <Link to="/" className="menu-links">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="menu-items">
                                            <Link
                                                id="StreamTour"
                                                to="/family-stream-contribution"
                                                className="menu-links"
                                            >
                                                The Stream
                                            </Link>
                                        </li>
                                        {auth.user.super_admin && (
                                            <li className="menu-items">
                                                <Link
                                                    to="/dashboard"
                                                    className="menu-links"
                                                >
                                                    Dashboard
                                                </Link>
                                            </li>
                                        )}
                                        <li className="menu-items">
                                            <Link
                                                to="/messages"
                                                className="menu-links"
                                            >
                                                Messages
                                            </Link>
                                        </li>
                                        <li className="menu-items">
                                            <Link
                                                to="/notifications"
                                                className="menu-links"
                                            >
                                                Notifications
                                            </Link>
                                        </li>
                                        <li className="menu-items">
                                            <Link
                                                to="/payment"
                                                className="menu-links"
                                            >
                                                Payment
                                            </Link>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        <li className="menu-items">
                                            <Link to="/" className="menu-links">
                                                Home
                                            </Link>
                                        </li>
                                        <li className="menu-items">
                                            <Link
                                                to="/about"
                                                className="menu-links"
                                            >
                                                ABOUT US
                                            </Link>
                                        </li>
                                        <li className="menu-items">
                                            <Link
                                                to="/how-do-it"
                                                className="menu-links"
                                            >
                                                HOW WE DO IT
                                            </Link>
                                        </li>
                                        <li className="menu-items">
                                            <Link
                                                to="/faqs"
                                                className="menu-links"
                                            >
                                                FAQ PAGE
                                            </Link>
                                        </li>
                                        <li className="menu-items">
                                            <Link
                                                to="/pricing"
                                                className="menu-links"
                                            >
                                                PRICING
                                            </Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-6 col-6">
                            <div className="right-side">
                                {/* <div className="search">
                                    <button
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#SearchModal"
                                        className="search-btn btn"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="20"
                                            fill="#17723e"
                                            className="bi bi-search"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </button>
                                </div> */}
                                <div className="button-group">
                                    {auth.token ? (
                                        <>
                                            <Link
                                                id="ProfileTour"
                                                to="/profile"
                                                className="lets-btn head-btns"
                                            >
                                                Profile
                                            </Link>
                                            <button
                                                type="button"
                                                className="lets-btn head-btns"
                                                onClick={Logoutfn}
                                            >
                                                Logout
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <Link
                                                to="/signup"
                                                className="lets-btn head-btns"
                                            >
                                                Let's Start
                                            </Link>
                                            <Link
                                                to="/signin"
                                                className="signin-btn head-btns"
                                            >
                                                Sign In
                                            </Link>
                                        </>
                                    )}
                                </div>
                                <div className="canvas-bar">
                                    <button onClick={() => setOpen(true)}>
                                        <div className="bars">
                                            <span className="---i"></span>
                                            <span className="--i"></span>
                                            <span className="---i"></span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* Header End Here */}

            {/* Canvas Header Start Here */}
            <div className={open ? "canvas_header active" : "canvas_header"}>
                <button className="for-close" onClick={() => setOpen(false)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        fill="#fff"
                        className="bi bi-x"
                        viewBox="0 0 16 16"
                    >
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </button>
                <ul className="menus">
                    <li className="menu-items">
                        <Link to="/" className="menu-links">
                            Home
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/about" className="menu-links">
                            ABOUT US
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/how-do-it" className="menu-links">
                            HOW WE DO IT
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/faqs" className="menu-links">
                            FAQ PAGE
                        </Link>
                    </li>
                    <li className="menu-items">
                        <Link to="/pricing" className="menu-links">
                            PRICING
                        </Link>
                    </li>
                    {!auth.token ? (
                        <>
                            <li className="menu-items">
                                <Link to="/signin" className="menu-links">
                                    Sign In
                                </Link>
                            </li>
                            <li className="menu-items">
                                <Link to="/signup" className="menu-links">
                                    Let's start
                                </Link>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="menu-items">
                                <Link to="/profile" className="menu-links">
                                    PROFILE
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
            {/* Canvas Header End Here */}

            {/* Search Bar Modal Start Here */}
            <div
                className="modal fade"
                id="SearchModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                    />
                                    <button
                                        data-dismiss="modal"
                                        onClick={e =>
                                            navigate("/search-results")
                                        }
                                    >
                                        <i
                                            className="fa fa-search"
                                            aria-hidden="true"
                                        ></i>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* Search Bar Modal End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(LogoutAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
