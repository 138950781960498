import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import DashboardSidebar from "./DashboardSidebar";
import { GetAdminsAction } from "../../redux/actions/admins";
import config from "../../config";
import { UPDATE_ADMIN_ENDPOINT } from "../../apis";
import moment from "moment";
import { Link } from "react-router-dom";

const MemberAccess = ({ admins, getAdminsAction, authToken }) => {
    const loadAdmins = async () => {
        try {
            await getAdminsAction();
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        loadAdmins().then();
    }, []);

    const [spinner, setSpinner] = useState(false);

    const updateUser = async (userId, directPost) => {
        try {
            setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            const data = JSON.stringify({ needs_approval: !directPost });
            await config.postApi(UPDATE_ADMIN_ENDPOINT(userId), headers, data);
            await getAdminsAction();
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            console.log(e);
        }
    };

    const updateUserStatus = async (userId, approved_at = null) => {
        try {
            setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            const data = JSON.stringify({ approved_at });
            await config.postApi(UPDATE_ADMIN_ENDPOINT(userId), headers, data);
            await getAdminsAction();
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Dashboard" />
            {/* InnerBanner End Here */}

            {/* Dashboard Sec Start Here */}
            <section className="dashboard_sec">
                <div className="container">
                    <div className="admin_box">
                        <div className="row">
                            <div className="col-lg-3">
                                <DashboardSidebar />
                            </div>
                            <div className="col-lg-8">
                                <div className="recently_added member_access">
                                    <div className="module_title">
                                        <h3>Member Access</h3>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Message</th>
                                                    <th>Direct Post</th>
                                                    <th>Approval</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {admins.map(admin => (
                                                    <tr>
                                                        <td>
                                                            {
                                                                admin.user
                                                                    .full_name
                                                            }
                                                        </td>
                                                        <td>
                                                            {admin.user.email}
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className="btn"
                                                                to={`/messages?user_id=${admin.user.id}`}
                                                            >
                                                                <i className="fa fa-comments"></i>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                className="form-control"
                                                                checked={
                                                                    !admin.needs_approval
                                                                }
                                                                onChange={e => {
                                                                    updateUser(
                                                                        admin
                                                                            .user
                                                                            .id,
                                                                        e.target
                                                                            .checked
                                                                    );
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="apporval">
                                                            {!admin.approved_at ? (
                                                                <div className="button-group">
                                                                    <button
                                                                        className="approved"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            updateUserStatus(
                                                                                admin
                                                                                    .user
                                                                                    .id,
                                                                                moment().format(
                                                                                    "YYYY-MM-DD"
                                                                                ) +
                                                                                    " 00:00:00"
                                                                            )
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fa fa-check"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <div className="button-group">
                                                                    <button
                                                                        className="denied"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            updateUserStatus(
                                                                                admin
                                                                                    .user
                                                                                    .id,
                                                                                null
                                                                            )
                                                                        }
                                                                    >
                                                                        <i
                                                                            className="fa fa-times"
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Dashboard Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    admins: state.admins,
    authToken: state.auth.token
});

const mapDispatchToProps = dispatch => ({
    getAdminsAction: async () => dispatch(await GetAdminsAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberAccess);
