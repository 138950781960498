import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/faqs.css";

const faqs = () => {
    const FaqsDat = [
        {
            id: 1,
            Question: "How can you protect my digital data for so long?",
            Answer:
                "Ancestream has combined the best of the new world with the best of the old. By using cemetery preservation law, which has worked for hundreds of years to maintain cemeteries around the world, and combining that with blockchain, we have developed a system that can endure centuries, if not millenia."
        },
        {
            id: 2,
            Question: "Is my information safe?",
            Answer:
                "Ancestream protects your data with 512 encryption, twice the accepted banking standard to ensure the security of your data, even if quantum computing becomes common place. We never sell any of our clients data, ever!"
        },
        {
            id: 3,
            Question: "What is a digital trust?",
            Answer:
                'We call it "The 75% Rule." When you join Ancestream as a paid member, 75% of the funds go directly into an interest bearing investment, and the cost to maintain your digital heirloom is carried by using a maximum of 75% of the interest gained that year. This way, we ensure that our maintenance fund continues to grow faster than inflation, and that we can continue to operate within our annual budget.'
        },
        {
            id: 4,
            Question: "Why do you use blockchain?",
            Answer:
                "We use blockchain for you and your future generations peace of mind. By encapsulating all of your digital treasures within our blockchain, you ensure that future generations know that everything you wanted to have preserved remains completely intact. "
        },
        {
            id: 5,
            Question: "How much does Ancestream cost?",
            Answer:
                "Our prices range between $995 and $5,995 depending on the amount of data you wish to preserve. To make that easier we offer financing for up to 36 months on larger packages. Just request a demo and our no pressure staff will explain in detail your options."
        },
        {
            id: 6,
            Question: "Can my whole family contribute?",
            Answer:
                "Yes, they can, but it is always your choice. The owner of the digital heirloom has administrative control. They can invite others to view, to add to their own profile or to add to others. "
        },
        {
            id: 7,
            Question: "What if I don’t like something someone else has added?",
            Answer:
                "At Ancestream, we know how to keep the family peace. We have added a special feature in the admin dashboard that allows you and only those designated as administrators to decide what uploads remain only on the cloud, and which are preserved for the future."
        }
    ];
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="FAQs" />
            {/* Inner Banner End Here */}

            {/* FAQs Sec Start Here */}
            <section className="faqs_sec">
                <div className="container">
                    <div id="accordion">
                        {FaqsDat?.map((data, index) => {
                            return (
                                <div className="card" key={index}>
                                    <div
                                        className="card-header"
                                        id={"heading" + data?.id}
                                    >
                                        <h5 className="mb-0">
                                            <button
                                                className="btn btn-link collapsed"
                                                data-toggle="collapse"
                                                data-target={
                                                    "#collapse" + data?.id
                                                }
                                                aria-expanded={
                                                    data?.id == 1
                                                        ? "true"
                                                        : "false"
                                                }
                                                aria-controls={
                                                    "collapse" + data?.id
                                                }
                                            >
                                                <h5>{data?.Question}</h5>
                                                <i
                                                    className="fa fa-plus"
                                                    aria-hidden="true"
                                                ></i>
                                            </button>
                                        </h5>
                                    </div>
                                    <div
                                        id={"collapse" + data?.id}
                                        className={
                                            data?.id == 1
                                                ? "collapse show"
                                                : "collapse"
                                        }
                                        aria-labelledby={
                                            "headingThree" + data?.id
                                        }
                                        data-parent="#accordion"
                                    >
                                        <div className="card-body">
                                            {data?.Answer}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>
            {/* FAQs Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default faqs;
