import React, { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/login.css";
import FormException from "../../exceptions/FormException";
import config from "../../config";
import { FORGOT_PASSWORD_ENDPOINT } from "../../apis";
import MyInput from "../../components/MyInput";
import { Spinner } from "reactstrap";

const ForgotPass = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const submit = async () => {
        try {
            setLoading(true);
            await config.postApi(
                FORGOT_PASSWORD_ENDPOINT,
                config.defaultHeaders,
                JSON.stringify({ email })
            );
            setLoading(false);
            setErrors({});
        } catch (e) {
            setLoading(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="Forgot Password" />
            {/* Inner Banner End Here */}

            {/* Login Sec Start Here */}
            <section className="login_sec">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        {/* <div className="col-lg-6">
                            <div className="img-box">
                                <figure><img src={AboutPic} alt="" className="img-fluid" /></figure>
                            </div>
                        </div> */}
                        <div className="col-lg-6">
                            <div className="form-box">
                                {/* <form action="/reset-password"> */}
                                <div className="heading-wrapper">
                                    <h4>
                                        Enter email to get forgot password link
                                    </h4>
                                </div>
                                <MyInput
                                    type="email"
                                    value={email}
                                    placeholder="Enter Email"
                                    label="Email"
                                    errors={errors.email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                {/* <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className="form-control"
                                    />
                                </div> */}
                                {/* <div className="form-group">
                                        <input type="password" placeholder="Password" className="form-control" />
                                    </div> */}
                                {/* <div className="remeberdv">
                                        <div className="form-group">
                                            <input type="checkbox" id="Remeber_me" className="form-check-input" />
                                            <label for="Remeber_me">Remember Me</label>
                                        </div>
                                        <div className="link">
                                            <Link to="#">Forgets Password?</Link>
                                        </div>
                                    </div> */}
                                <div className="button-group">
                                    <button
                                        className="btn"
                                        type="button"
                                        onClick={submit}
                                        disabled={loading}
                                    >
                                        {loading ? <Spinner /> : "Submit"}
                                    </button>
                                </div>
                                {/* <div className="bottom-bar">
                                        <p>Don't have account? <Link to="#">Sign Up</Link></p>
                                    </div> */}
                                {/* </form> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* login Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default ForgotPass;
