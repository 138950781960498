import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { WORKS_ENDPOINT } from "../../apis";
import config from "../../config";
import { connect } from "react-redux";

const AddWorkModal = ({
    toggle,
    isOpen,
    getData,
    editData,
    authToken,
    user_id = null
}) => {
    const [work_place, setWork_place] = useState("");
    const [description, setDescription] = useState("");
    const [start_date, setStart_date] = useState();
    const [end_date, setEnd_date] = useState();

    const handleCreate = async event => {
        event.preventDefault();
        if (editData) {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                let data = null;
                if (user_id) {
                    data = JSON.stringify({
                        work_place,
                        description,
                        start_date,
                        end_date,
                        user_id
                    });
                } else {
                    data = JSON.stringify({
                        work_place,
                        description,
                        start_date,
                        end_date
                    });
                }
                const res = await config.putApi(
                    `${WORKS_ENDPOINT}/${editData.id}`,
                    headers,
                    data
                );
                if (res) {
                    setWork_place("");
                    setDescription("");
                    setStart_date("");
                    setEnd_date("");
                    getData();
                    toggle();
                }

                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        } else {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                let data = null;
                if (user_id) {
                    data = JSON.stringify({
                        work_place,
                        description,
                        start_date,
                        end_date,
                        user_id
                    });
                } else {
                    data = JSON.stringify({
                        work_place,
                        description,
                        start_date,
                        end_date
                    });
                }
                const res = await config.postApi(WORKS_ENDPOINT, headers, data);
                if (res) {
                    setWork_place("");
                    setDescription("");
                    setStart_date("");
                    setEnd_date("");
                    getData();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (editData) {
            setWork_place(editData?.work_place);
            setDescription(editData?.description);
            setStart_date(editData?.start_date);
            setEnd_date(editData?.end_date);
        }
    }, [editData]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="h2">
                {editData ? "Edit Car" : "Add Car"}
            </ModalHeader>
            <ModalBody>
                <p className="small mb-1">Work Place</p>
                <input
                    type="text"
                    className="form-control form-control-sm mb-3"
                    value={work_place}
                    onChange={e => setWork_place(e.target.value)}
                />
                <p className="small mb-1">Description</p>
                <textarea
                    rows={5}
                    className="form-control form-control-sm mb-3"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
                <p className="small mb-1">Start Date</p>
                <input
                    type="date"
                    className="form-control form-control-sm mb-3"
                    value={start_date}
                    onChange={e => setStart_date(e.target.value)}
                />
                <p className="small mb-1">End Date</p>
                <input
                    type="date"
                    className="form-control form-control-sm mb-3"
                    value={end_date}
                    onChange={e => setEnd_date(e.target.value)}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn-sm" onClick={toggle}>
                    Cancel
                </Button>
                <Button className="btn-success btn-sm" onClick={handleCreate}>
                    {editData ? "Update" : "Create"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(AddWorkModal);
