import { GET_HOBBIES } from "../actions/type";

const hobbies = (state = [], action) => {
    switch (action.type) {
        case GET_HOBBIES:
            return action.payload;
        // case CLEAR_QUOTES:
        //     return [];
        default:
            return state;
    }
};

export default hobbies;
