import { FAMILY_TIMELINE_LOG_ENDPOINT } from "../../apis";
import config from "../../config";
import { GET_FAMILY_TIMELINE_LOGS } from "./type";

export const GetFamilyTimelineLogAction = () => async (dispatch, getState) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const res = await config.getApi(FAMILY_TIMELINE_LOG_ENDPOINT, headers);
    if (res) {
        dispatch({ type: GET_FAMILY_TIMELINE_LOGS, payload: res });
    }
};
