import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SECRETS_ENDPOINT } from "../../apis";
import config from "../../config";

const AddSecretsModal = ({
    toggle,
    isOpen,
    getData,
    editData,
    authToken,
    user_id = null
}) => {
    const [secret, setSecret] = useState("");

    const handleCreate = async event => {
        event.preventDefault();
        if (editData) {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                let data = null;
                if (user_id) {
                    data = JSON.stringify({
                        secret,
                        user_id
                    });
                } else {
                    data = JSON.stringify({
                        secret
                    });
                }
                const res = await config.putApi(
                    `${SECRETS_ENDPOINT}/${editData.id}`,
                    headers,
                    data
                );
                if (res) {
                    setSecret("");
                    getData();
                    toggle();
                }

                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        } else {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                let data = null;
                if (user_id) {
                    data = JSON.stringify({
                        secret,
                        user_id
                    });
                } else {
                    data = JSON.stringify({
                        secret
                    });
                }
                const res = await config.postApi(
                    SECRETS_ENDPOINT,
                    headers,
                    data
                );
                if (res) {
                    setSecret("");
                    getData();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (editData) {
            setSecret(editData?.secret);
        }
    }, [editData]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} className="h2">
                {editData ? "Edit Secrets" : "Add Secrets"}
            </ModalHeader>
            <ModalBody>
                <p className="small mb-1">Secrets</p>
                <input
                    type="text"
                    className="form-control form-control-sm mb-3"
                    // placeholder="Pet Name Here..."
                    value={secret}
                    onChange={e => setSecret(e.target.value)}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn-sm" onClick={toggle}>
                    Cancel
                </Button>
                <Button className="btn-success btn-sm" onClick={handleCreate}>
                    {editData ? "Update" : "Create"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(AddSecretsModal);
