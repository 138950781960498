import React, { useState } from "react";
import Header from "../../components/Header/Header.js";
import Footer from "../../components/Footer/Footer.js";
import InnerBanner from "../../components/InnerBanner/InnerBanner.js";
import "../../assets/css/dashboard.css";
import { NoPreview } from "../../constant/index.js";
import { MultiSelect } from "react-multi-select-component";
import { connect } from "react-redux";
import { Label, Spinner } from "reactstrap";
import { AddTimelineAction } from "../../redux/actions/timeline.js";
import FormException from "../../exceptions/FormException.js";
import MyInput from "../../components/MyInput.js";

const Dashboard = ({ user, members, addTimelineAction }) => {
    const [file, setFile] = useState({});
    const [fileUrl, setFileUrl] = useState("");
    const [title, setTiltle] = useState("");
    const [location, setLocation] = useState("");
    const [year, setYear] = useState("");
    const [tags, setTags] = useState([]);
    const tagsItems = tags.map(data => <li>{data?.label}</li>);
    const [description, setDescription] = useState("");

    const [errors, setErrors] = useState({});

    const [addingTimline, setAddingTimline] = useState(false);

    const onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
            console.log(event.target.files[0]);
            setFile(event.target.files[0]);
            setFileUrl(URL.createObjectURL(event.target.files[0]));
        }
    };

    const emptyFields = () => {
        setTiltle("");
        setFile({});
        setFileUrl("");
        setLocation("");
        setTags([]);
        setDescription("");
        setErrors({});
    };

    const addTimeline = async () => {
        try {
            setAddingTimline(true);
            let data = new FormData();
            data.append("title", title);
            data.append("location", location);
            data.append("description", description);
            data.append("year", year);
            tags.map(tag => {
                data.append("family_member_id[]", tag.value);
            });
            // data.append("month",);
            // data.append("date",);
            // data.append("month",);
            data.append("media", file);
            await addTimelineAction(data);
            emptyFields();
            setAddingTimline(false);
        } catch (e) {
            setAddingTimline(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner PageName="Add Timeline" />
            {/* InnerBanner End Here */}

            {/* Dashboard Sec Start Here */}
            <section className="dashboard_sec">
                <div className="container">
                    <div className="user_box">
                        <div className="title">
                            <h2>
                                Welcome{" "}
                                <span className="colored">
                                    {user.full_name}
                                </span>
                            </h2>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="welcome_heading">
                                    <h3>ADD TO A TIMELINE</h3>
                                </div>
                                <form action="">
                                    <div className="image_uploader">
                                        <div className="upload">
                                            <label htmlFor="FileUploader">
                                                <div>
                                                    Browse a photo{" "}
                                                    <i
                                                        className="fa fa-file-image-o"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>{" "}
                                                <p>png, jpg, webp, mp4</p>
                                            </label>
                                            <input
                                                hidden
                                                type="file"
                                                onChange={onImageChange}
                                                className="filetype"
                                                id="FileUploader"
                                            />
                                            <div
                                                style={{ marginBottom: 30 }}
                                                className={
                                                    `invalid-feedback ` +
                                                    (errors.media &&
                                                    errors.media.length > 0
                                                        ? "d-block"
                                                        : "")
                                                }
                                            >
                                                {errors.media &&
                                                    errors.media.map(
                                                        err => err
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Title"
                                                showLabel
                                                placeholder="Title"
                                                value={title}
                                                onChange={e =>
                                                    setTiltle(e.target.value)
                                                }
                                                inputId="title"
                                                errors={errors.title}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Location"
                                                showLabel
                                                placeholder="Location"
                                                value={location}
                                                onChange={e =>
                                                    setLocation(e.target.value)
                                                }
                                                inputId="location"
                                                errors={errors.location}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <MyInput
                                                label="Description"
                                                showLabel
                                                textarea
                                                placeholder="Description"
                                                value={description}
                                                onChange={e =>
                                                    setDescription(
                                                        e.target.value
                                                    )
                                                }
                                                inputId={"description"}
                                                errors={errors.description}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <MyInput
                                                label="Year"
                                                showLabel
                                                placeholder="Year"
                                                value={year}
                                                onChange={e =>
                                                    setYear(e.target.value)
                                                }
                                                inputId="year"
                                                errors={errors.year}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <Label htmlFor="tags">
                                                    Tags
                                                </Label>
                                                <MultiSelect
                                                    options={members.map(
                                                        member => ({
                                                            label:
                                                                member.full_name,
                                                            value: member.id
                                                        })
                                                    )}
                                                    value={tags}
                                                    onChange={setTags}
                                                    labelledBy="Select"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="button-group">
                                                <button
                                                    onClick={addTimeline}
                                                    type="button"
                                                    disabled={addingTimline}
                                                >
                                                    {addingTimline ? (
                                                        <Spinner />
                                                    ) : (
                                                        "Create a Timeline"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-6">
                                <div className="preview">
                                    <div className="title">
                                        <h3>PREVIEW</h3>
                                    </div>
                                    {file.type ? (
                                        <div className="img-preview">
                                            {file.type.split("/")[0] ==
                                            "image" ? (
                                                <img
                                                    key={`image_${file.name}`}
                                                    src={URL.createObjectURL(
                                                        file
                                                    )}
                                                    width="100%"
                                                    alt=""
                                                />
                                            ) : (
                                                <video
                                                    key={`video_${file.name}`}
                                                    src={fileUrl}
                                                    width="100%"
                                                    alt=""
                                                    controls
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className="no_preview">
                                            <img
                                                className="img-fluid"
                                                src={NoPreview}
                                                alt=""
                                            />
                                        </div>
                                    )}
                                    <div className="post-title">
                                        <h4>
                                            {title
                                                ? title
                                                : "The title of the post here"}
                                        </h4>
                                    </div>
                                    <div className="post-location">
                                        <h4>
                                            {year ? year : "Year"} |{" "}
                                            {location ? location : "location"}
                                        </h4>
                                    </div>
                                    <ul className="tags my-4">
                                        {tagsItems ? tagsItems : "Tag members"}
                                    </ul>
                                    <div className="post-description">
                                        <p>
                                            {description
                                                ? description
                                                : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Dashboard Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    user: state.auth.user,
    members: state.relations
});

const mapDispatchToProps = dispatch => ({
    addTimelineAction: async timeline =>
        dispatch(await AddTimelineAction(timeline))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
