import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toastr from "toastr";
import { VisaCard } from "./constant";
import FormException from "./exceptions/FormException";

const config = {
    showSuccess: message => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({ title: "Success", text: message, icon: "success" });
    },
    showError: message => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({ title: "Error", text: message, icon: "error" });
    },
    primaryColor: "#17723e",
    defaultHeaders: {
        "Content-Type": "application/json",
        Accept: "application/json"
    },
    bearerHeaders: token => ({
        ...config.defaultHeaders,
        Authorization: `Bearer ${token}`
    }),
    bearerMultipartHeaders: token => ({
        Accept: "application/json",
        Authorization: `Bearer ${token}`
    }),
    getApi: async (endpoint, headers) => {
        try {
            let url = "";
            if (endpoint.includes("http")) {
                url = endpoint;
            } else {
                url = process.env.REACT_APP_API_BASE_URI + endpoint;
            }
            const res = await (await fetch(url, { headers })).json();
            if (res.success) {
                return res.data;
            } else {
                toastr.error(res.message, "Error");
                if (res.errors) {
                    throw new FormException(res.errors);
                }
                return false;
            }
        } catch (e) {
            console.log(e);
        }
    },
    postApi: async (endpoint, headers, data, showAlert = true) => {
        try {
            let url = "";
            if (endpoint.includes("http")) {
                url = endpoint;
            } else {
                url = process.env.REACT_APP_API_BASE_URI + endpoint;
            }
            let res = await axios.post(url, data, { headers });
            if (res.status >= 400) {
                config.showError(res.data.message);
                if (res.data.errors) {
                    throw new FormException(res.data.errors);
                }
                return false;
            } else {
                if (showAlert) {
                    config.showSuccess(res.data.message);
                }
                return res.data.data;
            }
        } catch (e) {
            if (e.name === "AxiosError") {
                let res = e.response.data;
                config.showError(res.message);
                if (res.errors) {
                    throw new FormException(res.errors);
                }
                return false;
            }
        }
    },
    putApi: async (endpoint, headers, data, showAlert = true) => {
        try {
            let url = "";
            if (endpoint.includes("http")) {
                url = endpoint;
            } else {
                url = process.env.REACT_APP_API_BASE_URI + endpoint;
            }
            let res = await axios.put(url, data, { headers });
            if (res.status >= 400) {
                config.showError(res.data.message);
                if (res.data.errors) {
                    throw new FormException(res.data.errors);
                }
                return false;
            } else {
                if (showAlert) {
                    config.showSuccess(res.data.message);
                }
                return res.data.data;
            }
        } catch (e) {
            if (e.name === "AxiosError") {
                let res = e.response.data;
                config.showError(res.message);
                if (res.errors) {
                    throw new FormException(res.errors);
                }
                return false;
            }
        }
    },
    deleteApi: async (endpoint, headers, showAlert = true) => {
        try {
            let url = "";
            if (endpoint.includes("http")) {
                url = endpoint;
            } else {
                url = process.env.REACT_APP_API_BASE_URI + endpoint;
            }
            let res = await axios.delete(url, { headers });
            if (res.status >= 400) {
                config.showError(res.data.message);
                if (res.data.errors) {
                    throw new FormException(res.data.errors);
                }
                return false;
            } else {
                if (showAlert) {
                    config.showSuccess(res.data.message);
                }
                return res.data.data;
            }
        } catch (e) {
            if (e.name === "AxiosError") {
                let res = e.response.data;
                config.showError(res.message);
                if (res.errors) {
                    throw new FormException(res.errors);
                }
                return false;
            }
        }
    },
    returnAvatar: user => {
        if (user.avatar) {
            if (user.avatar.includes("http")) {
                return user.avatar;
            } else {
                return process.env.REACT_APP_AVATAR_URI + user.avatar;
            }
        } else {
            return (
                "https://ui-avatars.com/api/?name=" +
                user.full_name +
                "&background=random&size=256"
            );
        }
    },
    formatHashTags: caption => {
        let toReturn = (
            <p>
                {caption.split(" ").map(word => {
                    if (word.startsWith("#")) {
                        return (
                            <span
                                key={`caption_hashtag_${word}`}
                                className="hash-tag"
                            >
                                {word}{" "}
                            </span>
                        );
                    } else if (word.startsWith("@")) {
                        return (
                            <span
                                key={`caption_username_${word}`}
                                className="hash-tag"
                            >
                                {word}{" "}
                            </span>
                        );
                    }
                    return `${word} `;
                })}
            </p>
        );
        return toReturn;
    },
    pickFile: (cb, accept = "image/*") => {
        let picker = document.getElementById("picker");
        picker.setAttribute("accept", accept);
        picker.onchange = cb;
        picker.click();
    },
    returnCardImage: (brand = "visa") => {
        switch (brand) {
            case "Visa":
                return VisaCard;
            case "MasterCard":
                return require("./assets/img/mc.png");
            default:
                return VisaCard;
        }
    }
};

export default config;
