import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/about.css";
import { AboutPic } from "../../constant";

const About = () => {
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="About Us" />
            {/* Inner Banner End Here */}

            {/* About Us Sec Start Here */}
            <section className="universal_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="content-wrapper">
                                <h2 className="title">
                                    ABOUT <span className="colored">US</span>
                                </h2>
                                <p>
                                    {/* Ancestream was a dream of a group of genealogists */}
                                    Ancestream was developed by a group of
                                    family history enthusiasts with
                                    contributions from genealogists from around
                                    the world that were inspired by the idea
                                    that for the first time in history we could
                                    record ourselves, store fading photos,
                                    newspaper clippings and high definition
                                    video for future generations to truly get to
                                    know us. We wanted to offer advice and
                                    insights into what we had seen, what we had
                                    sacrificed and what values we held highest.
                                    We wanted, as we hope you do, to pass our
                                    accumulated wisdom on. We wanted to ensure
                                    that if placing family as a priority ever
                                    skipped a generation, all the accumulated
                                    family treasures wouldn’t be lost along with
                                    it.
                                    <br />
                                    <br />
                                    Our team includes experts experienced in the
                                    preservation of data, as well as in cemetery
                                    preservation. We have a resident in
                                    blockchain and since inception, we have
                                    become global leaders in the long term
                                    preservation of data.
                                    <br />
                                    {/* Focusing on the most remote possibilities of a Carrington Level event, a nuclear blast and an EMP detonation, Ancestream has built, on the equator, high in the Andes Mountains, <Link to="#">why the equator</Link> an off grid, underground storage that is itself stored within a Faraday Cage.  If that sounds over the top, it is! All to ensure that your digital heirloom will survive any type of disaster. */}
                                    {/* EMP detonation. Ancestream has built an <Link to="/faqs">Off the Grid, Faraday Cage, Underground Bunker, Andes Mountains, On the Equator</Link>. */}
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="img-box">
                                <figure>
                                    <img
                                        src={AboutPic}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Us Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default About;
