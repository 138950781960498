import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import admins from "./admins";
import auth from "./auth";
import paymentMethods from "./paymentMethods";
import posts from "./posts";
import relations from "./relations";
import timeline from "./timeline";
import chat from "./chat";
import quotes from "./quotes";
import hobbies from "./hobbies";
import familyTimelineLog from "./familyTimelineLog";
import newsFeed from "./newsFeed";

const rootReducer = combineReducers({
    auth,
    posts,
    relations,
    admins,
    timeline,
    paymentMethods,
    chat,
    quotes,
    hobbies,
    familyTimelineLog,
    newsFeed
});

const persistedReducer = persistReducer({ key: "root", storage }, rootReducer);

export default persistedReducer;
