import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { GetQuotesAction } from "../../redux/actions/quotes";
import RecentBar from "./RecentBar";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";
import { GetHobbysAction } from "../../redux/actions/hobbies";
import AddHobbyModal from "../../components/Hobbies/AddHobbyModal";
import config from "../../config";
import { HOBBY_ENDPOINT } from "../../apis";
import Spinner from "../../components/Spinner";
import { useParams } from "react-router-dom";

const Hobbies = ({ GetHobbysAction, authToken, hobbies }) => {
    const [spinner, setSpinner] = useState(false);
    const [addHobby, setAddHobby] = useState(false);
    const [hobbiesData, setHobbiesData] = useState([]);
    const [editHobbies, setEditHobbies] = useState();

    const { id } = useParams();

    const handleEdit = val => {
        setEditHobbies(val);
        setAddHobby(true);
    };

    const handleDelete = async val => {
        try {
            // setSpinner(true);

            const headers = config.bearerHeaders(authToken);
            console.log({ headers });
            // const data = JSON.stringify({ id: val?.id });
            const res = await config.deleteApi(
                `${HOBBY_ENDPOINT}/${val.id}`,
                headers
                // data
            );
            if (res) {
                console.log({ res });
                // getHobbies();
            }
            getHobbies();
            // setSpinner(false);
        } catch (e) {
            // setSpinner(false);
            console.log(e);
        }
    };

    const getHobbies = async () => {
        setSpinner(true);
        GetHobbysAction(id)
            .then(res => {
                console.log("res", res);
                setSpinner(false);
            })
            .catch(err => {
                console.log(err);
                setSpinner(false);
            });
        try {
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(
                `${HOBBY_ENDPOINT}?user_id=${id || ""}`,
                headers
            );
            if (res) {
                console.log({ res });
                setHobbiesData(res);
            }
            setSpinner(false);
        } catch (e) {
            console.log(e);
            setSpinner(false);
        }
    };

    useEffect(() => {
        getHobbies();
    }, []);

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Sec Start Here */}
            <InnerBanner PageName="Profile" />
            {/* InnerBanner Sec End Here */}

            <section className="profile_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            {/* Sidebar Start Here */}
                            <Sidebar user_id={id} />
                            {/* Sidebar End Here */}
                        </div>
                        <div className="col-lg-6">
                            <div className="hobbies_content">
                                <div className="title mb-4">
                                    <div className="row g-0 align-items-center">
                                        <div className="col-lg">
                                            <h3 className="mb-0">
                                                Hobbies and Interests
                                            </h3>
                                        </div>
                                        <div className="col-lg-auto">
                                            <button
                                                className="btn btn-sm btn-success br-8 shadow-sm"
                                                onClick={() => {
                                                    setEditHobbies();
                                                    setAddHobby(true);
                                                }}
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {spinner ? (
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-auto">
                                            <Spinner />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="table-resposnive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="hobbieds">
                                                        Hobbies/Interests
                                                    </th>
                                                    <th className="edit">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {hobbiesData?.map(
                                                    (val, index) => (
                                                        <tr key={val?.id}>
                                                            <td className="hobbieds">
                                                                {val?.hobby}
                                                            </td>
                                                            <td className="edit">
                                                                <button
                                                                    className="editBtn"
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            val
                                                                        )
                                                                    }
                                                                >
                                                                    <i
                                                                        className="fa fa-pencil-square-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </button>
                                                                <button
                                                                    className="deleteBtn"
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            val
                                                                        )
                                                                    }
                                                                >
                                                                    <i
                                                                        className="fa fa-trash-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {/* Right Sidebar Start Here */}
                            <RecentBar />
                            {/* Right Sidebar End Here */}
                        </div>
                    </div>
                </div>
            </section>

            {addHobby && (
                <AddHobbyModal
                    isOpen={addHobby}
                    toggle={() => setAddHobby(pre => !pre)}
                    getHobbies={getHobbies}
                    editHobbies={editHobbies}
                    user_id={id}
                />
            )}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token,
    hobbies: state.hobbies
});

const mapDispatchToProps = dispatch => ({
    GetHobbysAction: async (user_id = null) =>
        dispatch(await GetHobbysAction(user_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Hobbies);
