import React, { useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "../../assets/css/home.css";
import { CommentPic1, bannerVideo, review1, review2, review3, testiImg } from "../../constant";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MyInput from "../../components/MyInput";
import MySelect from "../../components/MySelect";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { RegisterAction } from "../../redux/actions/auth";
import FormException from "../../exceptions/FormException";
import { Spinner } from "reactstrap";

const Home2 = ({ RegisterAction }) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState(null);

    const [spinner, setSpinner] = useState(false);

    const [errors, setErrors] = useState({});

    const submit = async () => {
        try {
            setSpinner(true);
            await RegisterAction(
                firstName,
                lastName,
                email,
                password,
                dob,
                gender
            );
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
            if (e instanceof FormException) {
                setErrors(e.errors);
            }
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* New Banner Sec Start Here */}
            <section className="new_banner_sec">
                <div className="container">
                    <div className="banner-content position-relative">
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className="content-wrapper">
                                    <h1>
                                        Create your Family Tree Free Forever
                                    </h1>
                                    <h2>
                                        <span className="colored">
                                            It’s free and easy!
                                        </span>
                                    </h2>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="familytree_form">
                                    <div className="heading mb-4">
                                        <h4>
                                            Enter a few detail and see your
                                            family
                                        </h4>
                                    </div>
                                    <form action="">
                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                <MyInput
                                                    id="FirstName"
                                                    type="text"
                                                    placeholder="First Name"
                                                    value={firstName}
                                                    onChange={e =>
                                                        setFirstName(
                                                            e.target.value
                                                        )
                                                    }
                                                    errors={errors.first_name}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <MyInput
                                                    id="LastName"
                                                    type="text"
                                                    placeholder="Last Name"
                                                    value={lastName}
                                                    onChange={e =>
                                                        setLastName(
                                                            e.target.value
                                                        )
                                                    }
                                                    errors={errors.last_name}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <MyInput
                                                    id="Familyemail"
                                                    type="email"
                                                    placeholder="Email"
                                                    value={email}
                                                    onChange={e =>
                                                        setEmail(e.target.value)
                                                    }
                                                    errors={errors.email}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <MyInput
                                                    id="FamilyPassword"
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={e =>
                                                        setPassword(
                                                            e.target.value
                                                        )
                                                    }
                                                    errors={errors.password}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <MyInput
                                                    id="familydate"
                                                    type="date"
                                                    placeholder="Birth Date"
                                                    value={dob}
                                                    onChange={e =>
                                                        setDob(e.target.value)
                                                    }
                                                    errors={errors.dob}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <MySelect
                                                    id="familygender"
                                                    inputId="gender"
                                                    placeholder="Gender"
                                                    options={[
                                                        {
                                                            label: "Male",
                                                            value: "Male"
                                                        },
                                                        {
                                                            label: "Female",
                                                            value: "Female"
                                                        },
                                                        {
                                                            label: "Others",
                                                            value: "Others"
                                                        }
                                                    ]}
                                                    value={gender}
                                                    onChange={e =>
                                                        setGender(
                                                            e.target.value
                                                        )
                                                    }
                                                    errors={errors.gender}
                                                />
                                            </div>
                                            <div className="col-md-12">
                                                <div className="button-group">
                                                    <button
                                                        className="btn"
                                                        type="button"
                                                        onClick={submit}
                                                        disabled={spinner}
                                                    >
                                                        {spinner ? (
                                                            <Spinner
                                                                size={"sm"}
                                                            />
                                                        ) : (
                                                            "Start My Family Tree"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-video">
                    {/* <video playsinline="playsinline" muted="muted" preload="yes" autoplay="autoplay" loop="loop" width="100%">
                        <source src={bannerVideo} type="video/mp4" />
                    </video> */}
                    <ReactPlayer
                        width="100%"
                        height="100%"
                        url={bannerVideo}
                        loop={true}
                        playing={true}
                        muted={true}
                    />
                </div>
            </section>
            {/* New Banner Sec End Here */}

            {/* Start With You Sec Start Here */}
            <section className="start_you">
                <div className="container">
                    <div className="main-card">
                        <div className="row justify-content-center">
                            <div className="col-md-8">
                                <div className="card-body-content">
                                    <div className="content-box">
                                        <h3 className="title pb-2 blue-txt">
                                            It All Starts With You
                                        </h3>
                                        <p className="description">
                                            In just 2 minutes you can seed a
                                            family tree that will last for
                                            generations. Growing your tree is a
                                            breeze with Ancestream
                                        </p>
                                        <div className="content-seprator position-relative">
                                            <hr />
                                            <div className="seprator"></div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <h3 className="title pb-2 blue-txt">
                                            Add Your Favorite Videos and Photos
                                            to Your Timeline
                                        </h3>
                                        <p className="description">
                                            <span className="tick">✓</span> Tag
                                            everyone in your family tree
                                            <br />
                                            <span className="drk-blue">
                                                (we call it your stream)
                                            </span>
                                        </p>
                                        <p className="description">
                                            <span className="tick">✓</span> Add
                                            all your memories so the
                                            <br /> future will know everyone
                                            <br /> intimately
                                        </p>
                                        <p className="description">
                                            <span className="tick">✓</span> Add
                                            medical histories, favorite cars,
                                            <br /> quotes and more
                                        </p>
                                        <div className="content-seprator position-relative">
                                            <hr />
                                            <div className="seprator"></div>
                                        </div>
                                    </div>
                                    <div className="content-box">
                                        <h3 className="title pb-2 blue-txt">
                                            Invite Family and Friends to
                                            Contribute!
                                        </h3>
                                        <p className="description">
                                            <span className="tick">✓</span> You
                                            choose who joins the fun!
                                        </p>
                                        <p className="description">
                                            <span className="tick">✓</span>{" "}
                                            Ancestream is for your inner cirlce
                                        </p>
                                        <p className="description">
                                            <span className="tick">✓</span> Post
                                            photos, videos and more that you
                                            wouldn't share
                                            <br /> anywhere else
                                        </p>
                                        <p className="description">
                                            <span className="tick">✓</span> It
                                            is your private social media and
                                            family tree, All in
                                            <br />
                                            One!
                                        </p>
                                        <div className="content-seprator position-relative">
                                            <hr />
                                            <div className="seprator"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="familytree_form">
                        <div className="title pb-3">
                            <h3>Start my family tree</h3>
                        </div>
                        <form action="">
                            <div className="row">
                                <div className="col-md-4">
                                    <MyInput
                                        id="FirstName2"
                                        type="text"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={e =>
                                            setFirstName(e.target.value)
                                        }
                                        errors={errors.first_name}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <MyInput
                                        id="LastName2"
                                        type="text"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={e =>
                                            setLastName(e.target.value)
                                        }
                                        errors={errors.last_name}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <MySelect
                                        id="familygender2"
                                        inputId="gender"
                                        placeholder="Gender"
                                        options={[
                                            {
                                                label: "Male",
                                                value: "Male"
                                            },
                                            {
                                                label: "Female",
                                                value: "Female"
                                            },
                                            {
                                                label: "Others",
                                                value: "Others"
                                            }
                                        ]}
                                        value={gender}
                                        onChange={e =>
                                            setGender(e.target.value)
                                        }
                                        errors={errors.gender}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <MyInput
                                        id="Familyemail2"
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        errors={errors.email}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <MyInput
                                        id="FamilyPassword"
                                        type="password"
                                        placeholder="Password"
                                        value={password}
                                        onChange={e =>
                                            setPassword(e.target.value)
                                        }
                                        errors={errors.password}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <MyInput
                                        id="familydate2"
                                        type="date"
                                        placeholder="Birth Date"
                                        value={dob}
                                        onChange={e => setDob(e.target.value)}
                                        errors={errors.dob}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <div className="button-group mb-4 mt-3">
                                        <button
                                            className="startBtn forBtn rounded py-2 px-5 mr-3"
                                            type="button"
                                            onClick={submit}
                                            disabled={spinner}
                                        >
                                            {spinner ? (
                                                <Spinner size={"sm"} />
                                            ) : (
                                                "Start"
                                            )}
                                        </button>
                                        <button className="importBtn border-0 bg-transparent p-0">
                                            Import Tree
                                        </button>
                                    </div>
                                    <div className="privacy-content">
                                        <input
                                            type="checkbox"
                                            id="prvcyCheck"
                                            className="checkInput"
                                        />
                                        <label
                                            htmlFor="prvcyCheck"
                                            className="ml-2"
                                        >
                                            By signing up, you agree to the{" "}
                                            <Link to="/terms-conditions">
                                                Terms and conditions
                                            </Link>{" "}
                                            and{" "}
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            {/* Start With You Sec End Here */}

            {/* Review Sec Start Here */}
            <section className="review_sec">
                <div className="container">
                    <div className="testimonial-box">
                        <div className="clients-images text-center mb-4">
                            <ul>
                                <li>
                                    <img src={review1} alt="CommentPic1" />
                                </li>
                                <li>
                                    <img src={review2} alt="CommentPic1" />
                                </li>
                                <li>
                                    <img src={review3} alt="CommentPic1" />
                                </li>
                            </ul>
                        </div>
                        <div className="testimonial_slider">
                            <OwlCarousel
                                className="owl-theme"
                                loop
                                margin={10}
                                autoHeight={true}
                                nav
                                items="1"
                                autoplay={true}
                            >
                                <div>
                                    <div className="testi-slide">
                                        <h5>
                                            "Ancestream.com is a remarkable
                                            platform that caters to both
                                            seasoned genealogists and beginners
                                            like me. The seamless integration of
                                            technology and historical research
                                            is truly wonderful. Exploring my
                                            family history on this platform has
                                            been an enriching and deeply
                                            emotional experience. I can't thank
                                            Ancestream.com enough for helping me
                                            connect with my roots in such a
                                            meaningful way. I wholeheartedly
                                            recommend it to anyone interested in
                                            uncovering their family's past."
                                        </h5>
                                        <h6 className="text-center">
                                            <b>Thank you!</b>
                                        </h6>
                                        <p className="text-center">
                                            Rene R., CA, USA
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="testi-slide">
                                        <h5>
                                            "I am absolutely thrilled with my
                                            experience on Ancestream.com! This
                                            platform has truly exceeded my
                                            expectations in every aspect of
                                            exploring my family history. From
                                            the moment I signed up, I was
                                            impressed by the user-friendly
                                            interface and the wealth of features
                                            that the website offers."
                                        </h5>
                                        <h6 className="text-center">
                                            <b>Thank you!</b>
                                        </h6>
                                        <p className="text-center">
                                            Daniel C., FL, USA
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="testi-slide">
                                        <h5>
                                            " Wow! Ancestream is amazing! I
                                            created my family tree so easily. My
                                            favorite part is the family social
                                            media. I feel my information is safe
                                            and private, like nothing else I
                                            have used. "
                                        </h5>
                                        <h6 className="text-center">
                                            <b>Thank you!</b>
                                        </h6>
                                        <p className="text-center">
                                            Isabell M., NYC, USA
                                        </p>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            {/* Review Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapDispatchToProps = dispatch => ({
    RegisterAction: async (firstName, lastName, email, password, dob, gender) =>
        dispatch(
            RegisterAction(firstName, lastName, email, password, dob, gender)
        )
});

export default connect(null, mapDispatchToProps)(Home2);
