import React, { useEffect, useState } from "react";
import { NoPreview } from "../../constant";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import { connect } from "react-redux";
import config from "../../config";
import { CATEGORY_ENDPOINT } from "../../apis";
import { useParams } from "react-router-dom";

const UserCuration = ({ authToken }) => {
    const { id, category, event } = useParams();

    const [data, setData] = useState({});

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const headers = config.bearerHeaders(authToken);
            const res = await config.getApi(
                `${CATEGORY_ENDPOINT}/${event}?include_timeline=1&get_for_member=true&member_id=${id}`,
                headers
            );
            if (res) {
                setData(res);
                // setLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Start Here */}
            <InnerBanner
                PageName={
                    data && data.category ? data.category.name : "Curations"
                }
            />
            {/* InnerBanner End Here */}

            {/* User Curation Sec Start Here */}
            <section className="user-curation py-5">
                <div className="container">
                    <div className="row">
                        {data?.category?.family_timeline?.map(timeline => (
                            <div className="col-lg-4 col-md-6">
                                <div className="pending-curation-timeline mb-3">
                                    {timeline.media[0].media_type.split(
                                        "/"
                                    )[0] === "image" ? (
                                        <img
                                            src={`${process.env.REACT_APP_TIMELINE_URI}${timeline.media[0].media_file}`}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    ) : (
                                        <figure>
                                            <video
                                                controls
                                                style={{ height: 250 }}
                                                src={`${process.env.REACT_APP_TIMELINE_URI}${timeline.media[0].media_file}`}
                                            />
                                        </figure>
                                    )}

                                    <div className="details timeline-detail-content">
                                        <div className="inner-detail">
                                            <div className="name">
                                                <h3>{timeline.title}</h3>
                                            </div>
                                            <ul className="tags">
                                                {timeline?.tagged_members.map(
                                                    member => (
                                                        <li
                                                            key={`member_${member.member.id}`}
                                                        >
                                                            {
                                                                member.member
                                                                    .full_name
                                                            }
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                            <p className="description">
                                                {timeline.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {/* User Curation Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(UserCuration);
