import React from "react";
import { Link } from "react-router-dom";
import { FamilyCollage, FamilyTree, NewMemeber } from "../../constant";

const Protech = () => {
    return (
        <>
            <div className="custom-shape-divider-top-care">
                <svg
                    data-name="Layer 1"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        className="shape-fill"
                    ></path>
                </svg>
            </div>
            <section className="care_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="left_box wow animate__ animate__fadeInLeft animated">
                                <div className="comment_box comment_box1">
                                    <div className="text">
                                        {/* <p>Make your family tree upload photos videos and family</p> */}
                                        <p>
                                            Upload videos, photos, scans & more!
                                        </p>
                                    </div>
                                    <div className="imgBox">
                                        {/* <figure><img className="img-fluid" src="img/comment1.webp" alt="" /></figure> */}
                                    </div>
                                </div>
                                <div className="picture_box wow animate__animated animate__fadeInLeft">
                                    <figure>
                                        <img
                                            src={FamilyCollage}
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </figure>
                                </div>
                                <div className="comment_box comment_box2">
                                    {/* <div className="imgBox">
                                        <figure><img className="img-fluid" src={NewMemeber} alt="" /></figure>
                                    </div> */}
                                    <div className="text">
                                        {/* <p>New family member added</p> */}
                                        <p>
                                            Bring your story to life for generations to come.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="content-wrapper">
                                {/* <h2 className='wow animate__animated animate__fadeInRight'>They’ll <span className="colored">care</span> what you think, when you are gone</h2> */}
                                <h2 className="wow animate__animated animate__fadeInRight">
                                    Be The First To{" "}
                                    <span className="colored">Create</span> Your
                                    Digital Family Heirloom
                                </h2>
                                <p className="wow animate__animated animate__fadeInDown animate__delay-1s">
                                    So often caught up in life, we forget to
                                    memorialize our elders. We think we have
                                    heard the stories so many times that we will
                                    never forget, but we do. Give the most amazing gift you will ever give to generations to come: a memory of what was.
                                </p>
                                <div className="button-group wow animate__animated animate__fadeInUp animate__delay-2s">
                                    <Link to="/faqs" className="forBtn">
                                        LEARN MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Protech;
