import { HOBBY_ENDPOINT } from "../../apis";
import config from "../../config";
import { GET_HOBBIES } from "./type";

export const GetHobbysAction = (user_id = null) => async (
    dispatch,
    getState
) => {
    const headers = config.bearerHeaders(getState().auth.token);
    const res = await config.getApi(
        `${HOBBY_ENDPOINT}?user_id=${user_id || ""}`,
        headers
    );
    // if (res) {
    dispatch({ type: GET_HOBBIES, payload: res });
    // }
};
