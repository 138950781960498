import {
    GET_FAMILY_TIMELINE_LOGS,
    CLEAR_FAMILY_TIMELINE_LOGS
} from "../actions/type";

const familyTimelineLog = (state = [], action) => {
    switch (action.type) {
        case GET_FAMILY_TIMELINE_LOGS:
            return action.payload;
        case CLEAR_FAMILY_TIMELINE_LOGS:
            return [];
        default:
            return state;
    }
};

export default familyTimelineLog;
