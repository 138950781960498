import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import RecentBar from "./RecentBar";
import Sidebar from "./Sidebar";
import Quote from "../../components/Quotes/Quote";
import AddQuoteModal from "../../components/Quotes/AddQuoteModal";
import { connect, useSelector } from "react-redux";
import { QUOTES_ENDPOINT } from "../../apis";
import { mapRange } from "gsap";
import config from "../../config";
import { useParams } from "react-router-dom";

const FavouriteQuotes = ({ authToken }) => {
    const [spinner, setSpinner] = useState(false);
    const [addQuote, setAddQuote] = useState(false);
    const [quoteData, setQuoteData] = useState([]);
    const [editQuote, setEditQuote] = useState();

    const { id } = useParams();

    const toggleModal = () => {
        setAddQuote(!addQuote);
    };

    const handleEdit = val => {
        setEditQuote(val);
        setAddQuote(true);
    };

    const handleDelete = async val => {
        try {
            // setSpinner(true);

            const headers = config.bearerHeaders(authToken);
            console.log({ headers });
            const res = await config.deleteApi(
                `${QUOTES_ENDPOINT}/${val.id}`,
                headers
                // data
            );
            if (res) {
                console.log({ res });
                // getHobbies();
            }
            getQuotes();
            // setSpinner(false);
        } catch (e) {
            // setSpinner(false);
            console.log(e);
        }
    };

    // const quotes = useSelector(state => state.quotes);

    const getQuotes = async () => {
        setSpinner(true);
        try {
            const headers = config.bearerHeaders(authToken);

            const res = await config.getApi(
                `${QUOTES_ENDPOINT}?user_id=${id || ""}`,
                headers
            );
            if (res) {
                console.log({ res });
                setQuoteData(res);
            }
            setSpinner(false);
        } catch (e) {
            console.log(e);
            setSpinner(false);
        }
    };

    useEffect(() => {
        getQuotes();
    }, []);

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* InnerBanner Sec Start Here */}
            <InnerBanner PageName="Profile" />
            {/* InnerBanner Sec End Here */}

            <section className="profile_sec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            {/* Sidebar Start Here */}
                            <Sidebar user_id={id} />
                            {/* Sidebar End Here */}
                        </div>
                        <div className="col-lg-6">
                            <div className="hobbies_content">
                                <div className="title">
                                    <div className="row">
                                        <div className="col-lg">
                                            <h3 className="mb-0">
                                                Favorite Quotes
                                            </h3>
                                        </div>
                                        <div className="col-lg-auto">
                                            <button
                                                className="btn btn-sm btn-success br-8 shadow-sm"
                                                onClick={() => {
                                                    setEditQuote();
                                                    setAddQuote(true);
                                                }}
                                            >
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {spinner ? (
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-auto">
                                            {/* <Spinner /> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="table-resposnive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="hobbieds">
                                                        Quotes
                                                    </th>
                                                    <th className="edit">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {quoteData?.map(
                                                    (val, index) => (
                                                        <tr key={val?.id}>
                                                            <td className="hobbieds">
                                                                {val?.quote}
                                                            </td>
                                                            <td className="edit">
                                                                <button
                                                                    className="editBtn"
                                                                    onClick={() =>
                                                                        handleEdit(
                                                                            val
                                                                        )
                                                                    }
                                                                >
                                                                    <i
                                                                        className="fa fa-pencil-square-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </button>
                                                                <button
                                                                    className="deleteBtn"
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            val
                                                                        )
                                                                    }
                                                                >
                                                                    <i
                                                                        className="fa fa-trash-o"
                                                                        aria-hidden="true"
                                                                    ></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {/* <div className="row">
                                     {quote.map(singleQuote => (
                                        <div
                                            className="col-12"
                                            key={`quote_${singleQuote.id}`}
                                        >
                                            <Quote
                                                content={singleQuote.quote}
                                            />
                                        </div>
                                    ))}
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-3">
                            {/* Right Sidebar Start Here */}
                            <RecentBar />
                            {/* Right Sidebar End Here */}
                        </div>
                    </div>
                </div>
            </section>

            {addQuote && (
                <AddQuoteModal
                    isOpen={addQuote}
                    toggle={toggleModal}
                    getData={getQuotes}
                    editData={editQuote}
                    user_id={id}
                />
            )}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(FavouriteQuotes);
