import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="Term & Conditions" />
            {/* Inner Banner End Here */}

            {/* Policy Sec Start Here */}
            <section className="policy">
                <div className="container mt-5">
                    <div className="heading-wrapper">
                        <h3>Term & Conditions</h3>
                    </div>
                    <div className="content-wrapper">
                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **Ancestream Terms and Conditions**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        Welcome to Ancestream! These Terms and
                                        Conditions govern your use of our
                                        Software as a Service (SAAS) platform
                                        and related services. By accessing or
                                        using Ancestream&#39;s services, you
                                        agree to be bound by these Terms and
                                        Conditions. Please read them carefully
                                        before using our platform. If you do not
                                        agree with any part of these Terms and
                                        Conditions, you may not use our
                                        services.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **1. Definitions**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        1.1 &quot;Ancestream&quot; refers to the
                                        SAAS company and its software platform,
                                        including its website and services.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        1.2 &quot;User&quot; refers to any
                                        individual or entity accessing or using
                                        Ancestream&#39;s services.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        1.3 &quot;Content&quot; refers to any
                                        information, data, text, images, or
                                        materials uploaded, posted, displayed,
                                        or transmitted through Ancestream.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **2. Account Registration and Use**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        2.1 Eligibility: To use Ancestream, you
                                        must be at least 18 years old or have
                                        the legal capacity to enter into a
                                        binding agreement. By creating an
                                        account, you represent and warrant that
                                        you meet these eligibility requirements.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        2.2 Account Information: When
                                        registering for an account, you agree to
                                        provide accurate, complete, and
                                        up-to-date information. You are
                                        responsible for maintaining the
                                        confidentiality of your account login
                                        details and are fully responsible for
                                        all activities that occur under your
                                        account.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        2.3 Prohibited Activities: You may not
                                        use Ancestream for any illegal,
                                        unauthorized, or improper purposes. This
                                        includes, but is not limited to,
                                        infringing upon intellectual property
                                        rights, sending spam, or engaging in any
                                        activity that could harm
                                        Ancestream&#39;s systems or interfere
                                        with the use of the platform by others.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **3. Intellectual Property**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        3.1 Ownership: Ancestream retains all
                                        intellectual property rights to its
                                        software, platform, and any other
                                        materials or content provided on the
                                        platform. Users do not acquire any
                                        ownership rights by using the services.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        3.2 License: Ancestream grants Users a
                                        limited, non-exclusive,
                                        non-transferable, and revocable license
                                        to access and use the platform for
                                        personal or internal business purposes
                                        only. Users may not modify, distribute,
                                        reproduce, or create derivative works of
                                        any part of Ancestream&#39;s software or
                                        platform.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **4. Content and User Responsibility**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        4.1 User Content: Users are solely
                                        responsible for the content they upload,
                                        post, display, or transmit through
                                        Ancestream. By submitting Content, Users
                                        grant Ancestream a worldwide,
                                        royalty-free, non-exclusive license to
                                        use, modify, and distribute the Content
                                        solely for the purpose of providing the
                                        services.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        4.2 Prohibited Content: Users may not
                                        upload, post, or transmit any content
                                        that is illegal, defamatory, obscene,
                                        abusive, or violates the rights of
                                        others. Ancestream reserves the right to
                                        remove or disable access to any Content
                                        that violates these Terms and
                                        Conditions.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        4.3 Data Privacy: Ancestream respects
                                        the privacy of its Users. By using our
                                        services, you agree to our Privacy
                                        Policy, which governs the collection,
                                        use, and disclosure of personal
                                        information.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **5. Payment and Subscription**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        5.1 Fees: Users may be required to pay
                                        fees for certain features or
                                        subscriptions on Ancestream. Payment
                                        terms and details are outlined in the
                                        subscription plan or pricing section on
                                        our website.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        5.2 Automatic Renewal: If you choose a
                                        subscription plan, it will automatically
                                        renew at the end of each billing cycle
                                        unless you cancel it before the renewal
                                        date.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        5.3 Refunds: Ancestream may provide
                                        refunds according to its refund policy,
                                        which is available in our cancellation
                                        policy.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **6. Termination**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        6.1 Termination by User: You may cancel
                                        your account at any time by following
                                        the cancellation policy outlined on the
                                        Ancestream platform.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        6.2 Termination by Ancestream:
                                        Ancestream reserves the right to suspend
                                        or terminate your access to the platform
                                        at its discretion if you violate these
                                        Terms and Conditions or for any other
                                        reason.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **7. Disclaimer of Warranties**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        7.1 Ancestream provides its services
                                        &quot;as is&quot; and &quot;as
                                        available.&quot; We do not warrant that
                                        the platform will be uninterrupted,
                                        error-free, or secure. We disclaim all
                                        warranties, express or implied,
                                        including but not limited to warranties
                                        of merchantability, fitness for a
                                        particular purpose, and
                                        non-infringement.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **8. Limitation of Liability**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        8.1 In no event shall Ancestream be
                                        liable for any indirect, incidental,
                                        special, consequential, or punitive
                                        damages arising from or in connection
                                        with the use of its services, even if we
                                        have been advised of the possibility of
                                        such damages.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **9. Modification of Terms and
                                        Conditions**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        9.1 Ancestream may update these Terms
                                        and Conditions from time to time. Users
                                        will be notified of any significant
                                        changes, and continued use of the
                                        platform after such changes will
                                        constitute acceptance of the revised
                                        Terms and Conditions.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **10. Governing Law**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        10.1 These Terms and Conditions shall be
                                        governed by and construed in accordance
                                        with the laws of London, England,
                                        without regard to its conflict of laws
                                        principles.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        **11. Contact Us**
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        11.1 If you have any questions or
                                        concerns about these Terms and
                                        Conditions, please contact us at
                                        info@ancestream.com.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>

                        <p>
                            <span style={{ fontSize: 11 }}>
                                <span>
                                    <span style={{ color: "#000" }}>
                                        By using Ancestream, you acknowledge
                                        that you have read, understood, and
                                        agreed to be bound by these Terms and
                                        Conditions.
                                    </span>
                                </span>
                            </span>
                        </p>

                        <p>&nbsp;</p>
                    </div>
                </div>
            </section>
            {/* Policy Sec End Here */}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default Terms;
