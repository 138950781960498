import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { HOBBY_ENDPOINT } from "../../apis";
import config from "../../config";
import { connect } from "react-redux";

const AddHobbyModal = ({
    toggle,
    isOpen,
    getHobbies,
    editHobbies,
    authToken,
    user_id = null
}) => {
    const [hobby, sethobby] = useState("");

    const handleCreate = async event => {
        event.preventDefault();
        if (editHobbies) {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                let data = null;
                if (user_id) {
                    data = JSON.stringify({ hobby, user_id });
                } else {
                    data = JSON.stringify({ hobby });
                }

                const res = await config.putApi(
                    `${HOBBY_ENDPOINT}/${editHobbies.id}`,
                    headers,
                    data
                );
                if (res) {
                    sethobby("");
                    getHobbies();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        } else {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                let data = null;
                if (user_id) {
                    data = JSON.stringify({ hobby, user_id });
                } else {
                    data = JSON.stringify({ hobby });
                }
                const res = await config.postApi(HOBBY_ENDPOINT, headers, data);
                if (res) {
                    sethobby("");
                    getHobbies();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        }
    };

    useEffect(() => {
        if (editHobbies) {
            sethobby(editHobbies?.hobby);
        }
    }, [editHobbies]);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {editHobbies ? "Edit Hobbies" : "Add Hobbies"}
            </ModalHeader>
            <ModalBody>
                <p className="small mb-1">Hobby</p>
                <input
                    type="text"
                    className="form-control form-control-sm mb-3"
                    placeholder="Hobby Name Here..."
                    value={hobby}
                    onChange={e => sethobby(e.target.value)}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn-sm" onClick={toggle}>
                    Cancel
                </Button>
                <Button className="btn-success btn-sm" onClick={handleCreate}>
                    {editHobbies ? "Update" : "Create"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(AddHobbyModal);
