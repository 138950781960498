import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { QUOTES_ENDPOINT } from "../../apis";
import config from "../../config";

const AddQuoteModal = ({
    toggle,
    isOpen,
    getData,
    editData,
    authToken,
    user_id = null
}) => {
    const [quote, setQuote] = useState("");

    const handleCreate = async event => {
        event.preventDefault();
        if (editData) {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                let data = null;
                if (user_id) {
                    data = JSON.stringify({ quote, user_id });
                } else {
                    data = JSON.stringify({ quote });
                }
                const res = await config.putApi(
                    `${QUOTES_ENDPOINT}/${editData.id}`,
                    headers,
                    data
                );
                if (res) {
                    setQuote("");
                    getData();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        } else {
            try {
                // setSpinner(true);
                const headers = config.bearerHeaders(authToken);
                let data = null;
                if (user_id) {
                    data = JSON.stringify({ quote, user_id });
                } else {
                    data = JSON.stringify({ quote });
                }
                const res = await config.postApi(
                    QUOTES_ENDPOINT,
                    headers,
                    data
                );
                if (res) {
                    setQuote("");
                    getData();
                    toggle();
                }
                // setSpinner(false);
            } catch (e) {
                // setSpinner(false);
                console.log(e);
            }
        }
    };

    useEffect(() => {
        setQuote(editData?.quote);
    }, []);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {editData ? "Edit" : "Add"} Quote
            </ModalHeader>
            <ModalBody>
                {/* <input
                    type="text"
                    className="form-control form-control-sm mb-3 br-12"
                    placeholder="Reference"
                /> */}
                <textarea
                    rows={5}
                    className="form-control form-control-sm br-12"
                    placeholder="Quote"
                    value={quote}
                    onChange={e => setQuote(e.target.value)}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="btn-sm" onClick={toggle}>
                    Cancel
                </Button>
                <Button className="btn-success btn-sm" onClick={handleCreate}>
                    {editData ? "Update" : "Create"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const mapStateToProps = state => ({
    authToken: state.auth.token
});

export default connect(mapStateToProps)(AddQuoteModal);
