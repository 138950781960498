import {
    CLEAR_NEWS_FEED,
    GET_NEWS_FEED,
    GET_MORE_NEWS_FEED,
    ADD_NEWS_FEED
} from "../actions/type";

let initialState = {
    data: [],
    next_page_url: ""
};

const newsFeed = (state = initialState, action) => {
    switch (action.type) {
        case GET_NEWS_FEED:
            return action.payload;
        case GET_MORE_NEWS_FEED:
            return {
                ...state,
                data: [...state.data, ...action.payload.data],
                next_page_url: action.payload.next_page_url
            };
        case CLEAR_NEWS_FEED:
            return { ...initialState };
        case ADD_NEWS_FEED:
            return { ...state, data: [action.payload, ...state.data] };
        default:
            return state;
    }
};

export default newsFeed;
