import React, { useState } from "react";
import config from "../../config";
import { NEWSLETTER_EMAILS_ENDPOINT } from "../../apis";

const Newsletter = () => {
    const [email, setEmail] = useState("");

    const onSubmit = async e => {
        e.preventDefault();
        try {
            await config.postApi(
                NEWSLETTER_EMAILS_ENDPOINT,
                config.defaultHeaders,
                JSON.stringify({ email })
            );
            // if(){}
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <section className="newsletter_sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="heading-wrapper wow animate__animated animate__fadeInLeft">
                                <h3>
                                    Learn Tips &{" "}
                                    <span className="colored">Tricks</span> With
                                    Our Monthly Newsletter
                                </h3>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <form onSubmit={onSubmit}>
                                <div className="form-group wow animate__animated animate__fadeInRight">
                                    <input
                                        type="email"
                                        placeholder="Enter Your Email"
                                        className="form-control"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    <button type="submit" className="forBtn">
                                        Stay Hip
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Newsletter;
