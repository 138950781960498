export const LOGIN_ENDPOINT = "/auth/login";
export const REGISTER_ENDPOINT = "/auth/register";
export const FORGOT_PASSWORD_ENDPOINT = "/auth/forgot-password";
export const RESET_PASSWORD_ENDPOINT = "/auth/reset-password";
export const POST_ENDPOINT = "/posts";
export const COMMENT_ENDPOINT = post_id => `/posts/${post_id}/comments`;
export const RELATIONS_ENDPOINT = `/relations`;
export const CONNECTIONS_ENDPOINT = `/connections`;
export const RECENT_TIMELINE_ENDPOINT = `/dashboard/recent-timeline`;
export const ADD_ADMIN_ENDPOINT = `/dashboard/add-admin`;
export const REMOVE_ADMIN_ENDPOINT = id => `/dashboard/remove-admin/${id}`;
export const GET_ADMINS_ENDPOINT = `/dashboard/family-users`;
export const UPDATE_ADMIN_ENDPOINT = id => `/dashboard/update-user/${id}`;
export const UPDATE_TIMELINE_ENDPOINT = id =>
    `/dashboard/update-timeline/${id}`;
export const TIMLINE_ENDPOINT = `/timeline`;
export const PACKAGES_ENDPOINT = `/packages`;
export const PAYMENT_METHODS_ENDPOINT = `/payment-methods`;
export const SUBSCRIPTIONS_ENDPOINT = `/subscriptions`;
export const UPDATE_PROFILE_ENDPOINT = `/auth/update-profile`;
export const CATEGORY_ENDPOINT = `/categories`;
export const CHAT_ENDPOINT = `/chats`;
export const NOTIFICATIONS_ENDPOINT = `/notifications`;
export const QUOTES_ENDPOINT = `/quotes`;
export const HOBBY_ENDPOINT = `/hobbies`;
export const PETS_ENDPOINT = `/pets`;
export const SPORTS_ENDPOINT = `/sports`;
export const CARS_ENDPOINT = `/cars`;
export const MEDICALS_ENDPOINT = `/medicals`;
export const WORKS_ENDPOINT = `/works`;
export const SECRETS_ENDPOINT = `/secrets`;
export const PLACEDLIVED_ENDPOINT = `/places-lived`;

export const LIKE_ENDPOINT = `/likes`;

export const FAMILY_TIMELINE_LOG_ENDPOINT = `/family-timeline-logs`;

export const CONTACT_US_ENDPOINT = `/contact-us`;

export const IMAGE_BROWSER_ENDPOINT = `/image-browser`;

export const USER_INFO = id => `/users/${id}`;

export const NEWSLETTER_EMAILS_ENDPOINT = "/newsletter-emails";
export const NEWS_FEED_ENDPOINT = "/news-feed";
