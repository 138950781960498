import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import "../../assets/css/pricing.css";
import { PercentagePic } from "../../constant";
import config from "../../config";
import { PACKAGES_ENDPOINT, SUBSCRIPTIONS_ENDPOINT } from "../../apis";
import { useDispatch, useSelector } from "react-redux";
import { GetPaymentMethodsAction } from "../../redux/actions/paymentMethods";
import Swal from "sweetalert2";
import OverlaySpinner from "../../components/OverlaySpinner";
import { UPDATE_PROFILE } from "../../redux/actions/type";

const Pricing = () => {
    const [packages, setPackages] = useState([]);
    const [spinner, setSpinner] = useState(false);

    const paymentMethods = useSelector(state => state.paymentMethods);
    const authToken = useSelector(state => state.auth.token);
    const subscribedPackageId = useSelector(
        state => state.auth.user.subscribed_package_id
    );
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const loadPackages = async () => {
        const res = await config.getApi(
            PACKAGES_ENDPOINT,
            config.defaultHeaders
        );
        if (res) {
            setPackages(res);
        }
    };

    const loadPaymentMethods = async () => {
        await dispatch(await GetPaymentMethodsAction());
    };

    useEffect(() => {
        loadPackages().then(() => {
            if (authToken) {
                loadPaymentMethods().then();
            }
        });
    }, []);

    const buy = async package_id => {
        if (!authToken) {
            await Swal.fire({
                icon: "error",
                title: "Login",
                text: "Kindly Login First!"
            });
            return navigate("/signin");
        }
        if (paymentMethods.length == 0) {
            await Swal.fire({
                icon: "error",
                title: "No Payment Methods",
                text:
                    "Kindly add a payment method from payments page to continue!"
            });
            return navigate("/payment");
        }
        try {
            setSpinner(true);
            const headers = config.bearerHeaders(authToken);
            const res = await config.postApi(
                SUBSCRIPTIONS_ENDPOINT,
                headers,
                JSON.stringify({ package_id })
            );
            dispatch({ type: UPDATE_PROFILE, payload: res });
            setSpinner(false);
        } catch (e) {
            setSpinner(false);
        }
    };

    return (
        <>
            {/* Header Start Here */}
            <Header />
            {/* Header End Here */}

            {/* Inner Banner Start Here */}
            <InnerBanner PageName="Pricing" />
            {/* Inner Banner End Here */}

            {/* Pricing Sec Start Here */}
            <section className="pricing_sec">
                <div className="container">
                    <nav>
                        <div
                            className="nav nav-tabs"
                            id="nav-tab"
                            role="tablist"
                        >
                            <a
                                className="nav-item nav-link active"
                                id="nav-monthly-tab"
                                data-toggle="tab"
                                href="#nav-monthly"
                                role="tab"
                                aria-controls="nav-monthly"
                                aria-selected="true"
                            >
                                Monthly
                            </a>
                            <a
                                className="nav-item nav-link"
                                id="nav-lifetime-tab"
                                data-toggle="tab"
                                href="#nav-lifetime"
                                role="tab"
                                aria-controls="nav-lifetime"
                                aria-selected="false"
                            >
                                Lifetime
                            </a>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="nav-monthly"
                            role="tabpanel"
                            aria-labelledby="nav-monthly-tab"
                        >
                            <div className="row">
                                {packages
                                    .filter(
                                        singlePackage =>
                                            singlePackage.type == "monthly"
                                    )
                                    .map(singlePackage => (
                                        <div
                                            className="col-lg-4"
                                            key={`package_${singlePackage.id}`}
                                        >
                                            <div className="package-box">
                                                <div className="package-header">
                                                    <div className="package-title">
                                                        <h4 className="name">
                                                            {singlePackage.name}
                                                        </h4>
                                                        <h4 className="price">
                                                            $
                                                            {
                                                                singlePackage.discounted_price
                                                            }
                                                        </h4>
                                                    </div>
                                                    <i>
                                                        $
                                                        {
                                                            singlePackage.discounted_price
                                                        }{" "}
                                                        per month for 36 months,
                                                        then free forever!
                                                    </i>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                singlePackage.description
                                                        }}
                                                    ></p>
                                                </div>
                                                <ul className="including">
                                                    <li>
                                                        {singlePackage.storage}
                                                        GB storage
                                                    </li>
                                                    <li>
                                                        {
                                                            singlePackage.guaranteed_years
                                                        }{" "}
                                                        year guarantee
                                                    </li>
                                                    <li>
                                                        {
                                                            singlePackage.cents_per_gb
                                                        }{" "}
                                                        cents per gigabyte per
                                                        month*
                                                    </li>
                                                </ul>
                                                <div className="button-group">
                                                    {singlePackage.id ==
                                                    subscribedPackageId ? (
                                                        <button
                                                            disabled={true}
                                                            type="button"
                                                            className={
                                                                "disabled"
                                                            }
                                                        >
                                                            Active
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                buy(
                                                                    singlePackage.id
                                                                )
                                                            }
                                                        >
                                                            Buy Now
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                <div className="col-lg-12 mt-4">
                                    <p>
                                        <b>*Based on 100 year storage</b>
                                        <br />
                                        Custom plans are available for larger
                                        volumes, ideal for passing on
                                        stewardship to future generations or for
                                        Corporate histories.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="nav-lifetime"
                            role="tabpanel"
                            aria-labelledby="nav-lifetime-tab"
                        >
                            <div className="row">
                                {packages
                                    .filter(
                                        singlePackage =>
                                            singlePackage.type == "lifetime"
                                    )
                                    .map(singlePackage => (
                                        <div className="col-lg-4">
                                            <div className="package-box">
                                                <div className="package-header">
                                                    <div className="package-title">
                                                        <h4 className="name">
                                                            {singlePackage.name}
                                                        </h4>
                                                        <h4 className="price">
                                                            $
                                                            {
                                                                singlePackage.discounted_price
                                                            }
                                                        </h4>
                                                    </div>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                singlePackage.description
                                                        }}
                                                    ></p>
                                                </div>
                                                <ul className="including">
                                                    <li>
                                                        {singlePackage.storage}
                                                        GB storage
                                                    </li>
                                                    <li>
                                                        {
                                                            singlePackage.guaranteed_years
                                                        }{" "}
                                                        year guarantee
                                                    </li>
                                                    <li>
                                                        {
                                                            singlePackage.cents_per_gb
                                                        }{" "}
                                                        cents per gigabyte per
                                                        month*
                                                    </li>
                                                </ul>
                                                <div className="button-group">
                                                    <button
                                                        type="button"
                                                        onClick={() =>
                                                            buy(
                                                                singlePackage.id
                                                            )
                                                        }
                                                        // className="btn "
                                                    >
                                                        Buy Now
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                <div className="col-lg-12 mt-4">
                                    <p>
                                        <b>*Based on 100 year storage</b>
                                        <br />
                                        Custom plans are available for larger
                                        volumes, ideal for passing on
                                        stewardship to future generations or for
                                        corporate histories.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="heading-bar">
                        <h3 className="title">
                            Preserved Forever,{" "}
                            <span className="colored">Guaranteed</span>.
                        </h3>
                        <p>
                            Ancestream calculates its pricing based on a
                            completely different model of longevity. Our
                            financial model is completely transparent. Our
                            financial modeling extends for one hundred years,
                            though in reality the system can run in perpetuity.
                            Here is how we do it.
                            <br />
                        </p>
                    </div>
                    <div className="row my-5 align-items-center">
                        <div className="col-lg-7">
                            <div className="content-wrapper">
                                <div
                                    className="heading-bar"
                                    style={{ textAlign: "left" }}
                                >
                                    <h3 className="title">
                                        RULE OF{" "}
                                        <span className="colored">75%</span>
                                    </h3>
                                </div>
                                <p>
                                    For every dollar we receive, 75 cents goes
                                    directly into an interest bearing investment
                                    fund. Currently, we achieve 9.68% per annum,
                                    and only 75% of that interest is used per
                                    year to maintain the servers and hard copy
                                    storage of your digital heirloom. We only
                                    ever use 25% of the initial cost to cover
                                    usual business expenses, such as marketing,
                                    offices, employees and the usual overhead.
                                    <br />
                                    Everything else goes into your long term
                                    storage fund.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="img-box">
                                <figure>
                                    <img
                                        src={PercentagePic}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Pricing Sec End Here */}

            {spinner && <OverlaySpinner />}

            {/* Footer Start Here */}
            <Footer />
            {/* Footer End Here */}
        </>
    );
};

export default Pricing;
