import React, { useEffect } from "react";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import About from "../Pages/About/About";
import Home from "../Pages/Home/Home";
import How_We_Do from "../Pages/HowDo/How_We_Do";
import Faqs from "../Pages/FAQs/Faqs";
import Pricing from "../Pages/Pricing/Pricing";
import SignIn from "../Pages/Auth/SignIn";
import ForgotPass from "../Pages/Auth/ForgotPass";
import Profile from "../Pages/Profile/Profile";
import FamilyContribution from "../Pages/Profile/FamilyContribution";
import FavouriteQuotes from "../Pages/Profile/FavouriteQuotes";
import Hobbies from "../Pages/Profile/Hobbies";
import Dashboard from "../Pages/Dashboard/Dashboard";
import PendingApprovals from "../Pages/AdminDashboard/PendingApprovals";
import RecentAddition from "../Pages/AdminDashboard/RecentAddition";
import MemberAccess from "../Pages/AdminDashboard/MemberAccess";
import Owner from "../Pages/AddAdmin/Owner";
import Pets from "../Pages/Profile/Pets";
import Sports from "../Pages/Profile/Sports";
import Work from "../Pages/Profile/Work";
import PlacedLived from "../Pages/Profile/PlacedLived";
import Medical from "../Pages/Profile/Medical";
import Secrets from "../Pages/Profile/Secrets";
import Cars from "../Pages/Profile/Cars";
import Contact from "../Pages/Contact/Contact";
import PrivacyPolicy from "../Pages/Privacy/PrivacyPolicy";
import Signup from "../Pages/Auth/Signup";
import ResetPass from "../Pages/Auth/ResetPass";
import { connect } from "react-redux";
import SearchResult from "../Pages/SearchResult/SearchResult";
import UserProfile from "../Pages/Profile/UserProfile";
import Chat from "../Pages/Chat/Chat";
import AllNotifications from "../Pages/AllNotifications/AllNotifications";
import EditProfile from "../Pages/Profile/EditProfile";
import Timeline from "../Pages/Profile/Timeline";
import TimelineDetail from "../Pages/Profile/TimelineDetail";
import Payment from "../Pages/Payment/Payment";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import FlameTree from "../components/FlameTree";
import UserTimeline from "../Pages/UserTimeline/UserTimeline";
import TimelineCategory from "../Pages/UserTimeline/TimelineCategory";
import UserCuration from "../Pages/UserTimeline/UserCuration";
import Home2 from "../Pages/Home/Home2";
import Terms from "../Pages/Terms/Terms";

const PublicRoutes = ({ auth, authId }) => {
    useEffect(() => {
        if (authId) {
            const beamsClient = new PusherPushNotifications.Client({
                instanceId: process.env.REACT_APP_PUSHER_BEAM_INSTANCE_ID
            });

            beamsClient
                .start()
                .then(() =>
                    beamsClient.addDeviceInterest(`App.Models.User.${authId}`)
                )
                .then(() => beamsClient.getDeviceInterests())
                .then(interests =>
                    console.log(
                        "Successfully registered and subscribed!: ",
                        interests
                    )
                )
                .catch(console.error);
        }
    }, [authId]);

    return (
        <>
            <HashRouter>
                <Routes>
                    {/* <Route path="/flame" element={<FlameTree />} /> */}
                    {/* Public Pages Route */}
                    <Route path="/home" element={<Home />} />

                    <Route path="/about" element={<About />} />
                    <Route path="/how-do-it" element={<How_We_Do />} />
                    <Route path="/faqs" element={<Faqs />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/search-results" element={<SearchResult />} />
                    {!auth.token ? (
                        <>
                            <Route path="/" element={<Home2 />} />
                            <Route path="/signin" element={<SignIn />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route
                                path="/forgot-pass"
                                element={<ForgotPass />}
                            />
                            <Route
                                path="/reset-password"
                                element={<ResetPass />}
                            />
                        </>
                    ) : (
                        <>
                            <Route
                                path="/"
                                element={
                                    <Navigate to="/profile" replace={true} />
                                }
                            />
                            {/* Profile Pages Route */}
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/profile/:id" element={<Profile />} />
                            <Route
                                path="/family-stream-contribution"
                                element={<FamilyContribution />}
                            />
                            <Route
                                path="/favorite-quotes"
                                element={<FavouriteQuotes />}
                            />
                            <Route
                                path="/favorite-quotes/:id"
                                element={<FavouriteQuotes />}
                            />
                            <Route path="/hobbies" element={<Hobbies />} />
                            <Route path="/hobbies/:id" element={<Hobbies />} />
                            <Route path="/pets" element={<Pets />} />
                            <Route path="/pets/:id" element={<Pets />} />
                            <Route path="/sports" element={<Sports />} />
                            <Route path="/sports/:id" element={<Sports />} />
                            <Route path="/work" element={<Work />} />
                            <Route path="/work/:id" element={<Work />} />
                            <Route
                                path="/place-lived"
                                element={<PlacedLived />}
                            />
                            <Route
                                path="/place-lived/:id"
                                element={<PlacedLived />}
                            />
                            <Route path="/medical" element={<Medical />} />
                            <Route path="/medical/:id" element={<Medical />} />
                            <Route path="/secrets" element={<Secrets />} />
                            <Route path="/secrets/:id" element={<Secrets />} />
                            {/* Dashboard Pages Route */}
                            <Route
                                path="/add-timeline"
                                element={<Dashboard />}
                            />

                            {auth.user.super_admin && (
                                <>
                                    <Route
                                        path="/dashboard"
                                        element={<PendingApprovals />}
                                    />
                                    <Route
                                        path="/dashboard/recent-addition"
                                        element={<RecentAddition />}
                                    />
                                    <Route
                                        path="/dashboard/member-access"
                                        element={<MemberAccess />}
                                    />
                                    <Route
                                        path="/dashboard/add-admin"
                                        element={<Owner />}
                                    />
                                </>
                            )}

                            <Route
                                path="/edit-profile"
                                element={<EditProfile />}
                            />

                            {/* Chat Page */}
                            <Route path="/messages" element={<Chat />} />

                            {/* Notification Page */}
                            <Route
                                path="/notifications"
                                element={<AllNotifications />}
                            />
                            <Route path="/cars" element={<Cars />} />
                            <Route path="/cars/:id" element={<Cars />} />
                            <Route
                                path="/timelines/:id"
                                element={<Timeline />}
                            />
                            <Route
                                path="/timeline-detail/:id"
                                element={<TimelineDetail />}
                            />
                            <Route path="/payment" element={<Payment />} />

                            <Route
                                path="/signin"
                                element={<Navigate to="/profile" />}
                            />
                            <Route
                                path="/signup"
                                element={<Navigate to="/profile" />}
                            />
                            <Route
                                path="/forgot-pass"
                                element={<Navigate to="/profile" />}
                            />
                            <Route
                                path="/reset-password"
                                element={<Navigate to="/profile" />}
                            />
                            <Route
                                path="/member-timeline/:id"
                                element={<UserTimeline />}
                            />
                            <Route
                                path="/member-timeline/:id/:category"
                                element={<TimelineCategory />}
                            />
                            <Route
                                path="/member-timeline/:id/:category/:event"
                                element={<UserCuration />}
                            />
                        </>
                    )}
                    <Route path="/terms-conditions" element={<Terms />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/user-profile" element={<UserProfile />} />

                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </HashRouter>
        </>
    );
};

const mapStateToProps = state => ({
    auth: state.auth,
    authId: state.auth.user?.id
});

export default connect(mapStateToProps)(PublicRoutes);
