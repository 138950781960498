import React from "react";
import { NoPreview } from "../../constant";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const TimelineModal = ({ toggle, isOpen, getData }) => {
    console.log(getData);
    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle} className="timeline-modal">
                <ModalHeader toggle={toggle} className="h2"></ModalHeader>
                <ModalBody>
                    <div className="timeline-detail-modal">
                        <div className="img-box">
                            <figure>
                                <img
                                    src={`${process.env.REACT_APP_TIMELINE_URI}${getData[0]?.media[0].media_file}`}
                                    alt=""
                                    className="img-fluid"
                                />
                            </figure>
                        </div>
                        <div className="details">
                            <h2 className="title">{getData[0]?.title}</h2>
                            <div className="post-location">
                                <h4>
                                    {getData[0]?.year} | {getData[0]?.location}
                                </h4>
                            </div>
                            <ul className="tags">
                                <li>Lorem</li>
                                <li>Lorem</li>
                                <li>Lorem</li>
                                <li>Lorem</li>
                            </ul>
                            <div className="post-description">
                                <p>{getData[0]?.description}</p>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default TimelineModal;
