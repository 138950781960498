import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CATEGORY_ENDPOINT } from "../../apis";
import config from "../../config";
import { GetFamilyTimelineLogAction } from "../../redux/actions/familyTimelineLog";

const RecentBar = ({ recentData, getFamilyTimelineLog }) => {
    const [categories, setCategories] = useState([]);

    const authToken = useSelector(state => state.auth.token);

    const loadCategories = async () => {
        const headers = config.bearerHeaders(authToken);
        const res = await config.getApi(CATEGORY_ENDPOINT, headers);
        if (res) {
            setCategories(res);
        }
    };

    useEffect(() => {
        getFamilyTimelineLog().then();
        loadCategories().then();
    }, []);

    return (
        <>
            <div className="right_sidebar">
                <Link
                    to="/add-timeline"
                    id="AddTimelineTour"
                    className="btn btn-success mb-2"
                >
                    Add to Timeline
                </Link>
                <div className="your-timline-box">
                    <div className="title">
                        <h4>Your Timeline</h4>
                    </div>
                    <ul className="timeline-years">
                        {categories.map(category => (
                            <li key={`category_${category.id}`}>
                                <Link to={`/timelines/${category.id}`}>
                                    <div className="box">
                                        <span className="year">
                                            {category.name}
                                        </span>{" "}
                                        <span className="count">
                                            {category.timeline_count}
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        ))}
                        {/* <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2008</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2009</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2010</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2011</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2012</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2013</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2014</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2015</span>{" "}
                                    <span className="count">5</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2016</span>{" "}
                                    <span className="count">1</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2017</span>{" "}
                                    <span className="count">3</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2018</span>{" "}
                                    <span className="count">8</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2019</span>{" "}
                                    <span className="count">3</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2020</span>{" "}
                                    <span className="count">2</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2021</span>{" "}
                                    <span className="count">4</span>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/timelines">
                                <div className="box">
                                    <span className="year">2022</span>{" "}
                                    <span className="count">1</span>
                                </div>
                            </Link>
                        </li> */}
                    </ul>
                </div>
                <div className="recent-box">
                    <div className="title">
                        <h4>Recently added to the stream</h4>
                    </div>
                    <ul className="list">
                        {recentData?.map((data, index) => {
                            return (
                                <li className="menu-items" key={index}>
                                    <Link to="#">
                                        <figure>
                                            <img
                                                src={config.returnAvatar(
                                                    data.user
                                                )}
                                                alt="recently"
                                                className="img-fluid"
                                            />
                                        </figure>
                                        <div className="description">
                                            <p>{data?.log}</p>
                                        </div>
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    recentData: state.familyTimelineLog
});

const mapDispatchToProps = dispatch => ({
    getFamilyTimelineLog: async () =>
        dispatch(await GetFamilyTimelineLogAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(RecentBar);
