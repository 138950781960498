import React from "react";
import { Link } from "react-router-dom";
import {
    ApplestoreBtn,
    Logo,
    PlaystoreBtn,
    SOCIAL_LINKS,
    footerLogo
} from "../../constant";

const Footer = () => {
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="footer-content">
                                <div className="footer-logo">
                                    <Link to="">
                                        <figure>
                                            <img
                                                src={footerLogo}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </figure>
                                    </Link>
                                </div>
                                <p>
                                    In the 2020's, young or old, we all have our
                                    cell phones on us. We capture precious
                                    moments in photos or videos that remain
                                    stagnant in our storage for years without
                                    being properly curated. With Ancestream, all
                                    of your best photos and videos can be
                                    immediately preserved, not for a month or
                                    year in some cloud, but with triple
                                    protection for life, or even longer.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-content">
                                <div className="title">
                                    <h4>Useful Links</h4>
                                </div>
                                <ul className="menus">
                                    <li>
                                        <Link to="/terms-conditions">
                                            Terms & Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy">
                                            Privacy Policy
                                        </Link>
                                    </li>
                                    {/* <li>
                                        <a href="https://trader.tradovate.com/help">
                                            Help
                                        </a>
                                    </li> */}
                                    <li>
                                        <Link to="/contact-us">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="footer-content">
                                <div className="title">
                                    <h4>Social Links</h4>
                                </div>
                                <ul className="social-media">
                                    <li>
                                        <a
                                            href={SOCIAL_LINKS.facebook}
                                            target={"_blank"}
                                        >
                                            <i
                                                className="fa fa-facebook"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={SOCIAL_LINKS.youtube}
                                            target={"_blank"}
                                        >
                                            <i
                                                className="fa fa-youtube-play"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href={SOCIAL_LINKS.linkedin}
                                            target={"_blank"}
                                        >
                                            <i
                                                className="fa fa-linkedin"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a
                                            href={SOCIAL_LINKS.tiktok}
                                            target={"_blank"}
                                        >
                                            <i
                                                className="fa fa-brands fa-tiktok"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li> */}
                                    {/* <li>
                                        <a
                                            href={SOCIAL_LINKS.twitter}
                                            target={"_blank"}
                                        >
                                            <i
                                                className="fa fa-twitter"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li> */}
                                    <li>
                                        <a
                                            href={SOCIAL_LINKS.instagram}
                                            target={"_blank"}
                                        >
                                            <i
                                                className="fa fa-instagram"
                                                aria-hidden="true"
                                            ></i>
                                        </a>
                                    </li>
                                </ul>
                                {/* <div className="button-group">
                                    <a href="#" taget="_blank">
                                        <img
                                            src={PlaystoreBtn}
                                            alt="app-btn"
                                            className="img-fluid"
                                        />
                                    </a>
                                    <a href="#" target={"_blank"}>
                                        <img
                                            src={ApplestoreBtn}
                                            alt="app-btn"
                                            className="img-fluid"
                                        />
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyrights">
                <p>
                    Copyright 2019 - {new Date().getFullYear()} by Ancestream
                    Powered by{" "}
                    <a
                        target="_blank"
                        href="https://flamesware.com"
                        className="text-white"
                    >
                        Flames Ware
                    </a>
                </p>
            </div>
        </>
    );
};

export default Footer;
